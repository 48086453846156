import React, { Component } from 'react'
import PropTypes from 'prop-types'
//import { FormControl, TextField, Button } from '@material-ui/core';
//import Loadable from './Loadable';
//import DelayTextField from './forms/DelayTextField';
//import LanguageSelect from './youtube/localizations/LanguageSelect';
import Social from './social'
import styled from 'styled-components'


//import  * as modules from '../redux/social/modules/facebook'
/*
  state = {
    keywords: 'kaka ka, lalslds,dlskdl ,asdsdsdsd',
    keywordsArray: ['a', 'b', 'c']
  };

  handleChange(keywords, kwArray) {
    this.setState({
      keywords: keywords,
      keywordsArray: kwArray
    });
  }
        <hr/>
        <KeywordsEditor
          keywords={this.state.keywords}
          onChange={(keywords, kwArray) => this.handleChange(keywords, kwArray)}
          separator=','
          keywordsRegexp='[\s+]?,[\s+]?'
        />
        <hr/>
        <KeywordsEditor
          keywords={this.state.keywordsArray}
          onChange={(keywords, kwArray) => this.handleChange(keywords, kwArray)}
          separator=','
          keywordsRegexp='[\s+]?,[\s+]?'
        />


        */



const SocialContainers=styled.div`
width:100px;
`;
/*
const SocialContainer=styled.div`
width:100px;
`;
*/

export default class Sandbox extends Component {


  static propTypes = {
    prop: PropTypes.string
  }

  state = {
    loading: true,
    value: "1"
  }

  componentDidMount(){
    console.log(Social.getModules());
  }


  render() {

    return (
      <SocialContainers>
      { Social.getModulesArray().map( module => {
        const LC=module.loginComponent;
        const LC1=styled(LC)`
          width:400px;
        `;
        if(LC) return <LC1 key={module.id}/>;
        else return <div key={module.id}/>;
      })}
      </SocialContainers>
    )
  }
}
/*

        <Button onClick={() => this.setState({ loading: !this.state.loading })}>PUK</Button>
        <Loadable
          component={TextField}
          loading={this.state.loading}
          fullWidth
          variant="outlined"
          label="12323"
        >
        </Loadable>
        <Loadable
          component={FormControl}
          loading={this.state.loading}
          fullWidth
          variant="outlined"
        >
          <LanguageSelect
            value={this.state.language}
            onSelect={(language)=>this.setState({language:language})}
            />
          <DelayTextField
            label="Title:"
            helperText='Default title'
            defaultValue={this.state.value}
            variant="outlined"
            onChange={(event) => this.setState({ value: event.target.value })}
          />
          <DelayTextField label="Test" />
          <DelayTextField label="Test" />
          <DelayTextField label="Test" />
          <DelayTextField label="Test" />

        </Loadable>

        */