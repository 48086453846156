import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export default class AppMenuItem extends Component {
    static propTypes = {
        icon: PropTypes.object,
        text: PropTypes.string,
        path: PropTypes.string,
        onClick: PropTypes.func
    }

    render() {
        const Icon=this.props.icon;
        return (
            <ListItem button component={Link} to={this.props.path} onClick={this.props.onClick}>
                <ListItemIcon><Icon/></ListItemIcon>
                <ListItemText primary={this.props.text} />
            </ListItem>
        )
    }
}
