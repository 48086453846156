import {
    GOOGLE_CLIENT_LOAD,
    GOOGLE_CLIENT_LOAD_SUCCESS,
    GOOGLE_CLIENT_LOAD_FAILED,
    GOOGLE_CLIENT_INIT,
    GOOGLE_CLIENT_INIT_SUCCESS,
    GOOGLE_CLIENT_INIT_FAILED
} from '../actions/actionTypes'
import { reduceError } from '../../../../utils'

const initialState = {
    loading: false,
    loaded: false,
    ready: false,
    error: null
};


export default function defaultReducer(state = initialState, action) {
    state=reduceError(state,action);
    switch (action.type) {
        case GOOGLE_CLIENT_LOAD:
            return {
                ...state,
                loading: true,
                loaded: false
            }
        case GOOGLE_CLIENT_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true
            }
        case GOOGLE_CLIENT_LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: {
                    message: "Failed to load Google client",
                    details: action.error,
                    causeMessage: action.error.details,
                    causeCode: -1
                }
            }
        case GOOGLE_CLIENT_INIT:
            return {
                ...state,
                loading: true,
                ready: false
            }
        case GOOGLE_CLIENT_INIT_SUCCESS:
            return {
                ...state,
                loading: false,
                ready: true
            }
        case GOOGLE_CLIENT_INIT_FAILED:
            return {
                ...state,
                loading: false,
                ready: false,
                error: {
                    message: "Failed to init client",
                    details: action.error,
                    causeMessage: action.error.details,
                    causeCode: -1
                }
            }
        default:
            return state;
    }
}

