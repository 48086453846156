export const GOOGLE_CLIENT_LOAD = "GOOGLE_CLIENT_LOAD";
export const GOOGLE_CLIENT_LOAD_SUCCESS = "GOOGLE_CLIENT_LOAD_SUCCESS";
export const GOOGLE_CLIENT_LOAD_FAILED = "GOOGLE_CLIENT_LOAD_FAILED";

export const GOOGLE_CLIENT_INIT = "GOOGLE_CLIENT_INIT";
export const GOOGLE_CLIENT_INIT_SUCCESS = "GOOGLE_CLIENT_INIT_SUCCESS";
export const GOOGLE_CLIENT_INIT_FAILED = "GOOGLE_CLIENT_INIT_FAILED";



