import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import SocialAvatar from './SocialAvatar';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp'



const SignoutButton = styled(Button)`
padding-left: 6px !important;
padding-right: 6px !important;
padding-top: 0px !important;
padding-bottom: 0px !important;
`;

const ProfileContainer = styled.div`
display: flex;
flex-direction: row;
min-width: 120px;
padding: 5px;
align-items: center;
`;

const NameButtonContainer = styled.div`
display: flex;
flex-direction: column;
width: 100%;
margin-left: 6px;
`;


const NameContainer = styled.div`
display: block;
flex-wrap: nowrap;
white-space: nowrap;
text-overflow: ellipsis !important;
overflow: hidden;
`;


export class Profile extends Component {
    static propTypes = {
        name: PropTypes.string,
        isSigendIn: PropTypes.bool,
        avatar: PropTypes.string,
        badge: PropTypes.any,
        badgeColor: PropTypes.string,
        onSignOut: PropTypes.func
    }


    render() {
        return (
            <ProfileContainer
                className={this.props.className}
            >
                <SocialAvatar src={this.props.avatar} badge={this.props.badge} badgeColor={this.props.badgeColor} />
                <NameButtonContainer>
                    <NameContainer>{this.props.name}</NameContainer>
                    <SignoutButton
                        startIcon={<ExitToApp />}
                        size='small'
                        onClick={this.props.onSignOut}
                    >Sign out
                    </SignoutButton>
                </NameButtonContainer>
            </ProfileContainer>
        )
    }
}
