import { Component } from 'react'
import PropTypes from 'prop-types'

export default class PageTitle extends Component {
    static propTypes = {
        text: PropTypes.string
    }

    static defaultProps = {
        text: null
    }

    componentDidMount(){
        if(this.props.text) document.title="Dream Tuber::"+this.props.text;
        else document.title="Dream Tuber";
    }

    componentWillUnmount(){

    }

    render() {
        return '';
    }
}
