import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AppUrlAttendy from './AppUrlAttendy'
import { Route } from 'react-router-dom';

export default class AppRoute extends Component {
    static propTypes = {
        path: PropTypes.string,
        component: PropTypes.object
    }

    constructor(props) {
        super(props);
        this.getAttendyComponent = this.getAttendyComponent.bind(this);
    }

    getAttendyComponent = (props) => {
        const Component = this.props.component;
        props = {...props, ...this.props};
        return <AppUrlAttendy {...props} component={Component}/>;
    }


    render() {
        if(!this.props.component)throw new Error('AppRoute component property should be defined.')
        return (
            <Route path={this.props.path} component={this.getAttendyComponent}/>
        )
    }
}
