import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ButtonGroup from './ButtonGroup'
import Button from '@material-ui/core/Button'
import { CircularProgress } from '@material-ui/core'

import styled from 'styled-components'


const StyledCircularProgress=styled(CircularProgress)`
margin:0px !important;
`

export default class FormButtons extends Component {
    static propTypes = {
        onSave: PropTypes.func,
        saveCaption: PropTypes.string,
        saveDisabled: PropTypes.bool,
        onReset: PropTypes.func,
        resetCaption: PropTypes.string,
        resetDisabled: PropTypes.bool,
        onPost: PropTypes.func,
        postDisabled: PropTypes.bool,
        postCaption: PropTypes.string,
        inProgress: PropTypes.bool
    }

    static defaultProps = {
        saveCaption: "SAVE",
        postCaption: "POST",
        resetCaption: "RESET",
        postDisabled: false,
        resetDisabled: false,
        saveDisabled: false,
        inProgress: false
    }

    render() {
        return (
            <ButtonGroup>
                {this.props.inProgress && <StyledCircularProgress size={20}/>}
                <Button
                    variant="contained"
                    disabled={this.props.resetDisabled}
                    onClick={this.props.onReset}
                >
                    {this.props.resetCaption}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={this.props.saveDisabled}
                    onClick={this.props.onSave}
                >
                    {this.props.saveCaption}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={this.props.postDisabled}
                    onClick={this.props.onPost}
                >
                    {this.props.postCaption}
                </Button>
            </ButtonGroup>
        )
    }
}
