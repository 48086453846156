import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { getLanguages } from '../actions'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import { Autocomplete, Skeleton } from '@material-ui/lab'

class LanguageSelect extends Component {
    static propTypes = {
        label: PropTypes.string,
        onSelect: PropTypes.func,
        value: PropTypes.any,
        loading: PropTypes.bool,
        items: PropTypes.array,
        selectedIds: PropTypes.array
    }

    constructor(props) {
        super(props);
        this.updateLanguages = this.updateLanguages.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getGroupOption = this.getGroupOption.bind(this);
    }

    updateLanguages() {
        if ((!this.props.items)
            && (!this.props.loading)
            && (!this.props.error))
            this.props.getLanguages();
    }

    componentDidMount() {
        this.updateLanguages();
    }

    componentDidUpdate(){
        this.updateLanguages();
    }

    handleChange(event, language) {
        if (this.props.onSelect) {
            this.props.onSelect(language, (language) ? language.id : null);
        }
    }

    getValue() {
        if (this.props.value && ((typeof this.props.value) === 'string')) {
            if (this.props.items) {
                var matchedItems = this.props.items.filter((item) => {
                    return item.id === this.props.value;
                });
                if (matchedItems.length > 0)
                    return matchedItems[0];
                else return null;
            }
            return null;
        }
        return (this.props.value) ? this.props.value : null;
    }


    getItems() {
        var items = (this.props.items) ? this.props.items : [];
        if (this.props.selectedIds) {
            items = items.sort((a, b) => {
                var aInSelected = this.props.selectedIds.includes(a.id);
                var bInSelected = this.props.selectedIds.includes(b.id);
                if (aInSelected === bInSelected)
                    return a.snippet.name.localeCompare(b.snippet.name);
                else if (aInSelected) return -1;
                return 1;
            });

        }
        return items;
    }

    getGroupOption(option) {
        return this.props.selectedIds.includes(option.id) ? 'Defined' : 'To Add';
    }

    render() {
        if (this.props.loading)
            return (<Skeleton variant='rect' />);
        const selectedValue = this.getValue();
        const items = this.getItems();
        const groupBy = (this.props.selectedIds) ? this.getGroupOption : null;
        return (
            <Autocomplete
                options={items}
                getOptionLabel={(language) => language.snippet.name}
                onChange={this.handleChange}
                className={this.props.className}
                groupBy={groupBy}
                renderInput={
                    (params) => (this.props.loading) ? <CircularProgress size='2rem' /> :
                        <TextField
                            {...params}
                            label={(this.props.label) ? this.props.label : 'Language'}
                            variant={this.props.variant}
                        />
                }
                value={selectedValue}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    loading: state.youtube.languages.loading,
    items: state.youtube.languages.list.items,
    error: state.youtube.languages.error
})


export default connect(
    mapStateToProps,
    { getLanguages }
)(LanguageSelect)
