import {
    YOUTUBE_GET_VIDEO,
    YOUTUBE_GET_VIDEO_SUCCESS,
    YOUTUBE_GET_VIDEO_FAILURE,
    YOUTUBE_UPDATE_VIDEO,
    YOUTUBE_SELECT_VIDEO,
    YOUTUBE_RESET_VIDEO,
    YOUTUBE_SAVE_VIDEO,
    YOUTUBE_SAVE_VIDEO_SUCCESS,
    YOUTUBE_SAVE_VIDEO_FAILURE,
} from '../actions/actionTypes'

import { APP_RESET } from '../../../../actions/actionTypes'

import { getAppErrorFromErrorResponse } from '../../../google/utils'
import { reduceError } from '../../../../utils'
import cloneDeep from 'lodash/cloneDeep';
import objectSet from 'lodash/set';
import objectGet from 'lodash/get';
import isEqual from 'lodash/isEqual';


const initialState = {
    loading: false,
    data: null,
    originalData: null,
    saving: false,

};


export default defaultReducer;

export function defaultReducer(state = initialState, action) {
    state = reduceError(state, action);
    switch (action.type) {
        case APP_RESET:
            return initialState;
        case YOUTUBE_UPDATE_VIDEO:
            var newVideoData = cloneDeep(state.data);
            var originalData = (state.originalData) ? state.originalData : cloneDeep(state.data);
            var changed = !isEqual(action.value, objectGet(originalData, action.property));
            objectSet(newVideoData, action.property, action.value);
            return {
                ...state,
                data: newVideoData,
                changed: changed,
                originalData: originalData,
            }
        case YOUTUBE_GET_VIDEO:
            return {
                ...state,
                loading: true,
                error: null
            }
        case YOUTUBE_GET_VIDEO_SUCCESS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case YOUTUBE_GET_VIDEO_FAILURE:
            return {
                ...state,
                loading: false,
                error: getAppErrorFromErrorResponse('Failed to get video', action.errorResponse)
            }
        case YOUTUBE_SELECT_VIDEO:
            return {
                ...state,
                loading: false,
                data: action.data
            }

        case YOUTUBE_RESET_VIDEO:
            return {
                ...state,
                data: state.originalData,
                changed: false,
                originalData: null,
            }
        case YOUTUBE_SAVE_VIDEO:
            return {
                ...state,
                saving: true
            }
        case YOUTUBE_SAVE_VIDEO_SUCCESS:
            return {
                ...state,
                changed: false,
                originalData: null,
                saving: false
            }
        case YOUTUBE_SAVE_VIDEO_FAILURE:
            return {
                ...state,
                saving: false,
                error: getAppErrorFromErrorResponse('Failed to save video', action.errorResponse)
            }
        default:
            return state;
    }
}
