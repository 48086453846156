import {
    YOUTUBE_SELECT_CHANNEL,
    YOUTUBE_GET_CHANNEL_BY_ID,
    YOUTUBE_GET_CHANNEL_BY_ID_SUCCESS,
    YOUTUBE_GET_CHANNEL_BY_ID_FAILURE,
    YOUTUBE_UPDATE_CHANNEL,
    YOUTUBE_RESET_CHANNEL,
    YOUTUBE_SAVE_CHANNEL,
    YOUTUBE_SAVE_CHANNEL_SUCCESS,
    YOUTUBE_SAVE_CHANNEL_FAILURE,
} from '../actions/actionTypes'


import cloneDeep from 'lodash/cloneDeep';
import objectSet from 'lodash/set';
import objectGet from 'lodash/get';
import isEqual from 'lodash/isEqual';

import { APP_RESET } from '../../../../actions/actionTypes'

import { getAppErrorFromErrorResponse } from '../../../google/utils'



import { reduceError } from '../../../../utils'


/*
const someChannel =
{
    id: 2,
    snippet: {
        thumbnails: {
            default:{
                url: "url"
            }
        },
    },
    brandingSettings: {
        title: "Some channel",
        description: "Some channel description"
    }
};
*/

const someChannel = null;

const initialState = {
    loading: false,
    changed: false,
    data: someChannel,
    originalData: null,
    saving: false
};



export default defaultReducer;

export function defaultReducer(state = initialState, action) {
    state = reduceError(state, action);
    switch (action.type) {
        case APP_RESET:
            return initialState;
        case YOUTUBE_UPDATE_CHANNEL:
            var newChannelData = cloneDeep(state.data);
            var originalData = (state.originalData) ? state.originalData : cloneDeep(state.data);
            var changed = !isEqual(action.value, objectGet(originalData, action.property));
            objectSet(newChannelData, action.property, action.value);
            return {
                ...state,
                changed: changed,
                data: newChannelData,
                originalData: originalData
            }
        case YOUTUBE_GET_CHANNEL_BY_ID:
            return {
                ...state,
                loading: true,
                error: null
            }
        case YOUTUBE_GET_CHANNEL_BY_ID_SUCCESS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case YOUTUBE_GET_CHANNEL_BY_ID_FAILURE:
            return {
                ...state,
                loading: false,
                error: getAppErrorFromErrorResponse('Failed to get channel', action.errorResponse)
            }
        case YOUTUBE_SELECT_CHANNEL:
            return {
                ...state,
                loading: false,
                changed: false,
                data: action.data,
                originalData: cloneDeep(action.data)
            }
        case YOUTUBE_RESET_CHANNEL:
            return {
                ...state,
                data: state.originalData,
                changed: false,
                originalData: null,
            }
        case YOUTUBE_SAVE_CHANNEL:
            return {
                ...state,
                saving: true
            }
        case YOUTUBE_SAVE_CHANNEL_SUCCESS:
            return {
                ...state,
                changed: false,
                originalData: null,
                saving: false
            }
        case YOUTUBE_SAVE_CHANNEL_FAILURE:
            return {
                ...state,
                saving: false,
                error: getAppErrorFromErrorResponse('Failed to save video', action.errorResponse)
            }
        default:
            return state;
    }
}
