import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab'


export default class Loadable extends Component {
    static propTypes = {
        loading: PropTypes.bool,
        component: PropTypes.any.isRequired,
        skeletonProps: PropTypes.object
    }

    static defaultProps = {
        loading: false
    }

    getContainerOrSkeleton(loading,component){
        return (loading)?<Skeleton width="95%">{component}</Skeleton>:component;
    }

    getChildComponent(props) {
        const ChildComponent = this.props.component;
        var { children, ...mainProps } = props;
        return (<ChildComponent {...mainProps}>
            {React.Children.map(this.props.children, (child, index) => {
                return this.getContainerOrSkeleton(this.props.loading,child);//(this.props.loading)?<Skeleton width="95%">{child}</Skeleton>:child;//<LoadableContainer key={index} loading={this.props.loading} component={(props) => child} {...child.props} />
            })}
        </ChildComponent>);

    }

    render() {
        var { component, loading, ...props } = this.props;
        if (!this.props.children) return this.getContainerOrSkeleton(this.props.loading,this.getChildComponent(props));// return <LoadableContainer {...this.props} />;
        return this.getChildComponent(props);
    }
}
