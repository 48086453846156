export const YOUTUBE_SELECT_CHANNEL = "YOUTUBE_SELECT_CHANNEL";
export const YOUTUBE_UPDATE_CHANNEL="YOUTUBE_UPDATE_CHANNEL";
export const YOUTUBE_GET_CHANNEL_BY_ID = "YOUTUBE_GET_CHANNEL_BY_ID";
export const YOUTUBE_GET_CHANNEL_BY_ID_SUCCESS = "YOUTUBE_GET_CHANNEL_BY_ID_SUCCESS";
export const YOUTUBE_GET_CHANNEL_BY_ID_FAILURE = "YOUTUBE_GET_CHANNEL_BY_ID_FAILURE";


export const YOUTUBE_RESET_CHANNEL = "YOUTUBE_RESET_CHANNEL";
export const YOUTUBE_SAVE_CHANNEL = "YOUTUBE_SAVE_CHANNEL";
export const YOUTUBE_SAVE_CHANNEL_SUCCESS = "YOUTUBE_SAVE_CHANNEL_SUCCESS";
export const YOUTUBE_SAVE_CHANNEL_FAILURE = "YOUTUBE_SAVE_CHANNEL_FAILURE";
