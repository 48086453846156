
import {
    VK_LOGIN,
    VK_LOGOUT,
    VK_PROFILE_LOAD_FAILURE,
    VK_PROFILE_LOAD_SUCCESS,
} from '../actions/actionTypes'

const initialState = {
    error: null,
    profile: null,
    isSignedIn: false
};





export default function defaultReducer(state = initialState, action) {
    switch (action.type) {
        case VK_LOGIN:
            return {
                ...state,
                error: null,
                isSignedIn: true
            }
        case VK_LOGOUT:
            return initialState;
        case VK_PROFILE_LOAD_FAILURE:
            return {
                ...state,
                error: action.error,
            }
        case VK_PROFILE_LOAD_SUCCESS:
            return {
                ...state,
                profile: action.profile,
            }

        default:
            return state;
    }
}