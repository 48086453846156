import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import styled from 'styled-components'


const StyledButton = styled(Button)`
${props => (props.color) ? 'color: ' + props.color : ''};
${props => (props.backgoroundColor) ? 'background-color: ' + props.backgoroundColor : ''};
&:hover {
        ${props => (props.backgoroundColorHover) ? 'background-color: ' + props.backgoroundColorHover : ''};
};
`

const ButtonText = styled.span`
    display: block;
    flex-wrap: nowrap;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    overflow: hidden;
`;


export default class SocialLoginButton extends Component {
    static propTypes = {
        name: PropTypes.string,
        icon: PropTypes.any,
        onLogin: PropTypes.func,
        color: PropTypes.string,
        backgoroundColor: PropTypes.string,
        backgoroundColorHover: PropTypes.string,
    }

    getIcon() {
        if (!this.props.icon) return null;
        const Icon = this.props.icon;
        return (<Icon fontSize='small' width="20" heigh="20" />);
    }

    render() {
        return (
            <StyledButton
                startIcon={this.getIcon()}
                onClick={this.props.onLogin}
                color={this.props.color}
                backgoroundColor={this.props.backgoroundColor}
                backgoroundColorHover={this.props.backgoroundColorHover}
                className={this.props.className}
            >
                <ButtonText>LOGIN WITH {this.props.name}</ButtonText>
            </StyledButton>
        )
    }
}
