import {
    GOOGLE_CLIENT_LOAD,
    GOOGLE_CLIENT_LOAD_SUCCESS,
    GOOGLE_CLIENT_LOAD_FAILED,
    GOOGLE_CLIENT_INIT,
    GOOGLE_CLIENT_INIT_SUCCESS,
    GOOGLE_CLIENT_INIT_FAILED
} from './actionTypes'

import { googleSignInSuccess, googleSignOutSuccess } from '../../user/actions'
import GoogleClient from '../../../../apis/Google/GoogleClient';

export function googleClientLoadCall() {
    return {
        type: GOOGLE_CLIENT_LOAD,
    };
}

export function googleClientLoadSuccess() {
    return {
        type: GOOGLE_CLIENT_LOAD_SUCCESS,
    };
}



export function googleClientLoadFailure(error) {
    return {
        type: GOOGLE_CLIENT_LOAD_FAILED,
        error
    };
}


export function googleClientInitCall() {
    return {
        type: GOOGLE_CLIENT_INIT,
    };
}

export function googleClientInitSuccess() {
    return {
        type: GOOGLE_CLIENT_INIT_SUCCESS,
    };
}



export function googleClientInitFailure(error) {
    return {
        type: GOOGLE_CLIENT_INIT_FAILED,
        error
    };
}

export function googlieClientLoad(onSuccess, onFailure) {
    return function (dispatch) {
        dispatch(googleClientLoadCall());
        if (window.gapi.client) {
            dispatch(googleClientLoadSuccess());
            if (onSuccess) onSuccess();
        } else {
            GoogleClient.load()
                .then(() => {
                    dispatch(googleClientLoadSuccess());
                    if (onSuccess) onSuccess();
                },
                    () => {
                        dispatch(googleClientLoadFailure());
                        if (onFailure) onFailure();
                    }
                );
        }
    }
}

export function googleClientInit() {
    return function (dispatch) {
        dispatch(googleClientInitCall());
        GoogleClient.init((isSigendIn) => dispatch((isSigendIn)?googleSignInSuccess():googleSignOutSuccess()))
            .then(() => {
                dispatch(googleClientInitSuccess());
            },
                (error) => {
                    dispatch(googleClientInitFailure(error));
                }
            );
    }
}

export function googleClientLoadAndInit() {
    return function (dispatch) {
        GoogleClient.load()
            .then(
                () => {
                    dispatch(googleClientLoadSuccess());
                    return true;
                },
                (error) => {
                    dispatch(googleClientLoadFailure(error));
                    return false;
                }
            )
            .then(
                (clientLoaded) => {
                    if(clientLoaded)
                        dispatch(googleClientInit());
                }
            )
    }
}
