

export const simpleGoogleUserMap = (state) => {
    return { 
        isSignedIn: state.google.user.isSignedIn,
        profile: state.google.user.profile,
        loading: state.google.user.loading || state.google.client.loading
    };
}


export const simpleGoogleClientMap = (state) => {
    return {
        loaded: state.google.client.loaded,
        loading: state.google.client.loading,
        ready: state.google.client.ready,
        error: state.google.client.error
    }
}

/*
{
                    message: 'Failed to get channel',
                    details: action.errorResponse,
                    causeMessage: action.errorResponse.result.error.message,
                    causeCode: action.errorResponse.result.error.code
                }
*/


export function getAppErrorFromErrorResponse(message,errorResponse){
    var causeMessage=null;
    if(errorResponse.error)causeMessage=errorResponse.error.message;
    if((errorResponse.result)&&(errorResponse.result.error)){
        causeMessage=errorResponse.result.error.message;
    }
    var causeCode=null;
    if(errorResponse.error)causeCode=errorResponse.error.code;
    if((errorResponse.result)&&(errorResponse.result.error)){
        causeCode=errorResponse.result.error.code;
    }

    return {
        message: message,
        details: errorResponse,
        causeMessage: causeMessage, 
        causeCode: causeCode
    }
}