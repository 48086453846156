export const VK_CLIENT_LOAD = "VK_CLIENT_LOAD";
export const VK_CLIENT_LOAD_SUCCESS = "VK_CLIENT_LOAD_SUCCESS";
export const VK_CLIENT_LOAD_FAILURE = "VK_CLIENT_LOAD_FAILURE";

export const VK_LOGIN = "VK_LOGIN";
export const VK_LOGOUT = "VK_LOGOUT";

export const VK_PROFILE_LOAD = "VK_PROFILE_LOAD";
export const VK_PROFILE_LOAD_SUCCESS = "VK_PROFILE_LOAD_SUCCESS";
export const VK_PROFILE_LOAD_FAILURE = "VK_PROFILE_LOAD_FAILURE";
