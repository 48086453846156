import {
    YOUTUBE_GET_VIDEOS,
    YOUTUBE_GET_VIDEOS_SUCCESS,
    YOUTUBE_GET_VIDEOS_FAILURE
} from '../actions/actionTypes'

import { APP_RESET } from '../../../../actions/actionTypes'
import { YOUTUBE_SELECT_CHANNEL } from '../../channel/actions/actionTypes'

import { reduceError } from '../../../../utils/index'


const initialState = {
    loading: false,
    error: null,
    list: {
        page: null,
        pageToken: null,
        nextPageToken: null,
        prevPageToken: null,
        totalResults: null,
        term: '',
        items: null
    }
};


export default function defaultReducer(state = initialState, action) {
    state = reduceError(state, action);
    switch (action.type) {
        case APP_RESET:
        case YOUTUBE_SELECT_CHANNEL:
            return initialState;
        case YOUTUBE_GET_VIDEOS:
            return {
                ...state,
                loading: true
            }
        case YOUTUBE_GET_VIDEOS_SUCCESS:
            var pageTokens=state.list.pageTokens;
            if(!pageTokens)pageTokens=[];
            if(action.page){
                pageTokens[0]=null;
                pageTokens[action.page]=action.pageToken;
            }else pageTokens=null;

            var prevPageToken=action.successResponse.result.prevPageToken;
            if((!prevPageToken)&&(action.page)&&(pageTokens)){
                prevPageToken=(pageTokens[action.page-1])?pageTokens[action.page-1]:null;
            }
            return {
                ...state,
                loading: false,
                list: {
                    pageTokens: pageTokens,
                    items: action.successResponse.result.items,
                    totalResults: action.successResponse.result.pageInfo.totalResults,
                    resultsPerPage: action.successResponse.result.pageInfo.resultsPerPage,
                    nextPageToken: action.successResponse.result.nextPageToken,
                    prevPageToken: prevPageToken,
                    pageToken: action.pageToken,
                    page: action.page,
                    term: action.term
                }

            }
        case YOUTUBE_GET_VIDEOS_FAILURE:
            return {
                ...state,
                loading: false,
                error: {
                    message: 'Failed to get videos',
                    details: action.errorResponse,
                    causeMessage: action.errorResponse.result.error.message,
                    causeCode: action.errorResponse.result.error.code
                }
            }
        default:
            return state;
    }
}
