import { combineReducers } from "redux";
import google from '../components/google/reducer'
import youtube from '../components/youtube/reducer'
import social from '../components/social/reducer'


const initialState = {
    allIds: [],
    byIds: {}
  };


function app(state = initialState, action) {
    return {
        ...state
    }
}

export default combineReducers({
    google,
    app,
    youtube,
    social
});