import React, { Component } from 'react';
import { connect } from 'react-redux';
import { googleSignOut } from '../user/actions'
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import GoogleProfileLabel from './GoogleProfileLabel';
import { simpleGoogleUserMap } from '../utils';
import styled from 'styled-components';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ListItemIcon, ListItemText } from '@material-ui/core';

const NonSelectableMenuItem = styled(MenuItem)`
padding: 0px !important;
margin: 0px 10px 5px 10px !important;
opacity: 1 !important;
`;

class GoogleProfileMenuButton extends Component {
    static propTypes = {
        showSignIn: PropTypes.bool,
        showProfile: PropTypes.bool,
        showSignOut: PropTypes.bool,
        theme: PropTypes.oneOf(['light', 'dark'])
    }

    static defaultProps = {
        showSignIn: true,
        showProfile: true,
        showSignOut: true,
        theme: 'dark'
    }

    state = {
        anchorEl: null
    }
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    setAnchorEl(anchorEl) {
        this.setState({
            anchorEl: anchorEl
        });
    }

    handleClick(event) {
        this.setAnchorEl(event.currentTarget);
    };

    handleClose() {
        this.setAnchorEl(null);
    };

    signOut(){
        this.props.googleSignOut();
    }

    render() {
        if (!this.props.isSignedIn) return '';
        const profileImage=(this.props.profile)?this.props.profile.getImageUrl():'';
        return (
            <>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                    <Avatar src={profileImage}/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                >
                    <NonSelectableMenuItem disabled>
                    <GoogleProfileLabel theme='light'/>
                    </NonSelectableMenuItem>
                    <Divider/>
                    <MenuItem onClick={this.props.googleSignOut}>
                        <ListItemIcon>
                            <ExitToAppIcon/>
                        </ListItemIcon>
                        <ListItemText>Sign Out</ListItemText>
                    </MenuItem>
                </Menu>
            </>
        )
    }
}

export default connect(
    simpleGoogleUserMap,
    { googleSignOut }
)(GoogleProfileMenuButton)