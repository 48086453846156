import {
    YOUTUBE_GET_CHANNELS,
    YOUTUBE_GET_CHANNELS_SUCCESS,
    YOUTUBE_GET_CHANNELS_FAILURE,
} from './actionTypes'


import YouTubeClient from '../../../../apis/YouTube/YouTubeClient'



export function getUserChannelsCall(){
    return {
        type: YOUTUBE_GET_CHANNELS
    };
}


export function getUserChannelsSuccess(response,pageToken=null,page){
    YouTubeClient.normalizeId(response.result.items);
    return {
        type: YOUTUBE_GET_CHANNELS_SUCCESS,
        successResponse: response,
        pageToken: pageToken,
        page: page
    };
}

export function getUserChannelsFailure(errorResponse){
    return {
        type: YOUTUBE_GET_CHANNELS_FAILURE,
        errorResponse: errorResponse
    };
}


export function getUserChannels(maxResults,term,pageToken,page){
    return function(dispatch){
        dispatch(getUserChannelsCall());
        YouTubeClient.list({
            pageToken: pageToken,
            maxResults: maxResults
        })
        .then(
            (youtubeResponse) => dispatch(getUserChannelsSuccess(youtubeResponse,pageToken,page)),
            (errorResponse) => dispatch(getUserChannelsFailure(errorResponse))
        ).catch(
            (errorResponse) => dispatch(getUserChannelsFailure(errorResponse))
        );
    }
}

