import React from 'react'
import { Alert } from 'react-bootstrap'

export default class AppAlerts extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, errorInfo: null };
        this.closeAlert = this.closeAlert.bind(this);
    }

    componentDidCatch(error, errorInfo) {
        // Catch errors in any components below and re-render with error message
        console.log('Component did catch =>', error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
        // You can also log error messages to an error reporting service here
    }

    closeAlert() {
        this.setState({
            error: null,
            errorInfo: null
        });

    }

    render() {
        if (this.state.errorInfo) {
            const message = this.state.error.message.replace(/(<([^>]+)>)/ig, "");
            return (
                <Alert variant='danger' dismissible onClose={this.closeAlert}>{message}
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                        {this.state.error && this.state.error.toString()}
                        <br />
                        {this.state.errorInfo.componentStack}
                    </details>

                </Alert>
            );
        }
        return this.props.children;
    }
}




