import GoogleClient from '../../../../apis/Google/GoogleClient'; 
import { 
    GOOGLE_SIGNIN_CALL, 
    GOOGLE_SIGNIN_SUCCESS, 
    GOOGLE_SIGNIN_FAILURE,
    GOOGLE_SIGNOUT_CALL, 
    GOOGLE_SIGNOUT_SUCCESS, 
    GOOGLE_SIGNOUT_FAILURE 
} from './actionTypes'

import { appReset } from '../../../../actions'


function actionTemplate(actionType,signedInFlag, profile, loading=false ){
    return {
        type: actionType,
        isSignedIn: signedInFlag,
        profile: profile,
        loading: loading
    };

}

export function googleSignInCall() {
    return actionTemplate(GOOGLE_SIGNIN_CALL,false,null,true);
}

export function googleSignInSuccess() {
    return actionTemplate(GOOGLE_SIGNIN_SUCCESS,true,GoogleClient.getBasicProfile());
}



export function googleSignInFailure() {
    return actionTemplate(GOOGLE_SIGNIN_FAILURE,false,null);
}

export function googleSignOutCall() {
    return actionTemplate(GOOGLE_SIGNOUT_CALL,false,null,true);
}

export function googleSignOutSuccess() {
    return actionTemplate(GOOGLE_SIGNOUT_SUCCESS,false,null);
}



export function googleSignOutFailure() {
    return actionTemplate(GOOGLE_SIGNOUT_FAILURE,false,null);
}


export function googleSignOut() {
    return function (dispatch) {
        dispatch(googleSignOutCall());
        GoogleClient.signOut(
            () => {
                dispatch(googleSignOutSuccess());
                dispatch(appReset());
            },
            () => {
                dispatch(googleSignOutFailure());
                dispatch(appReset());
            }
        );
    }
}

export function googleSignIn() {
    return function (dispatch) {
        dispatch(googleSignInCall());
        GoogleClient.signIn(
            () => dispatch(googleSignInSuccess()),
            () => {
                dispatch(googleSignInFailure());
                dispatch(appReset());
            }
        );
    }
}