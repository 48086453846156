import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
    fbDoLogin
} from '../actions'
import Facebook from '@material-ui/icons/Facebook';
import SocialLoginButton from '../../../components/SocialLoginButton'


export class FBLoginButton extends Component {
    static propTypes = {
        fbDoLogin: PropTypes.func,
    }
    render() {

        return (

            <SocialLoginButton
                name="Facebook"
                icon={Facebook}
                onLogin={this.props.fbDoLogin}
                backgoroundColor='#1877F2'
                backgoroundColorHover='#5890FF'
                color="white"
                className={this.props.className}
            />
        )
    }
}

const mapDispatchToProps = {
    fbDoLogin
}

export default connect(null, mapDispatchToProps)(FBLoginButton)
