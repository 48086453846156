import React, { Component } from 'react'
import GoogleClient from '../../../apis/Google/GoogleClient';
import PropTypes from 'prop-types'
import GoogleProfileMenuButton from './GoogleProfileMenuButton';
import { connect } from 'react-redux';
import { googleSignInSuccess, googleSignInFailure } from '../user/actions'
import { simpleGoogleUserMap } from '../utils/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import styled from 'styled-components'


const HiddingDiv = styled.div`
    ${props => (props.hidden)?'display: none;':'' }
    width: ${props => (props.width)}px;
    height: ${props => (props.height)}px;
`;


class GoogleSignInButton extends Component {

    static propTypes = {
        scopes: PropTypes.string,
        theme: PropTypes.oneOf(['light', 'dark']),
        useProfileMenu: PropTypes.bool,
        width: PropTypes.number,
        height: PropTypes.number
    }

    static defaultProps = {
        scopes: GoogleClient.SCOPES,
        useProfileMenu: true,
        theme: 'dark',
        width: 192,
        height: 40
    }

    static buttonCounter = 0;

    state = {
        id: 'googleSignInButton' + (GoogleSignInButton.buttonCounter++)
    }

    callRender() {
        if (this.props.isSignedIn) return;
        GoogleClient.renderButton(
            this.state.id,
            this.props.googleSignInSuccess,
            this.props.googleSignInFailure,
            this.props.theme,
            this.props.width,
            this.props.height
            );
        //renderButton(divId,onSuccess,onFailure,theme='dark',width=192,height=40)

    }

    componentDidMount() {
        this.callRender();
    }

    componentDidUpdate() {
        this.callRender();
    }

    render() {
        return (
            <>
                {this.props.loading && <CircularProgress color='inherit' />}
                {this.props.isSignedIn && <GoogleProfileMenuButton />}
                <HiddingDiv 
                    id={this.state.id} 
                    hidden={this.props.loading || this.props.isSignedIn}
                    width={this.props.width}
                    height={this.props.height}
                    />
            </>
        )
    }
}


export default connect(
    simpleGoogleUserMap,
    { googleSignInSuccess, googleSignInFailure }
)(GoogleSignInButton)