import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';
import AppDrawerChildren from './AppDrawerChildren'



const StyledDrawer = styled(Drawer)`
    width: var(--drawer-width);
    flex-shrink:0;
    .app-drawer-paper{
        z-index: var(--drawer-z-index) !important;
        width: var(--drawer-width);
    }
    `;

const StyeledDrawerContent = styled.div`
text-align:center;
overflow: auto;
`;

export default class AppDrawer extends Component {
    static propTypes = {
        open: PropTypes.bool,
        onDrawerClose: PropTypes.func,
        anchor: PropTypes.string
    }
    static defaultProps = {
        open: false,
        onDrawerClose: null,
        anchor: 'left'
    }

    constructor(props) {
        super(props);
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this);
    }

    handleDrawerToggle() {
        if (this.props.onDrawerClose) this.props.onDrawerClose();
    }

    render() {
        const container = window !== undefined ? () => window.document.body : undefined;
        return (
            <div aria-label="application drawer">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden smUp implementation="css">
                    <StyledDrawer
                        container={container}
                        variant="temporary"
                        anchor={this.props.anchor}
                        open={this.props.open}
                        onClose={this.handleDrawerToggle}
                        style={{
                            zIndex: 'var(--drawer-z-index)'
                        }}
                        classes={{
                            paper: 'app-drawer-paper'
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        <Toolbar />
                        <StyeledDrawerContent>
                            <AppDrawerChildren handleAppDrawerClose={this.handleDrawerToggle}>
                                {this.props.children}
                            </AppDrawerChildren>
                        </StyeledDrawerContent>
                    </StyledDrawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <StyledDrawer
                        variant="permanent"
                        open
                        classes={{ paper: 'app-drawer-paper' }}
                    >
                        <Toolbar />
                        <StyeledDrawerContent>
                            <AppDrawerChildren>
                                {this.props.children}
                            </AppDrawerChildren>
                        </StyeledDrawerContent>
                    </StyledDrawer>
                </Hidden>
            </div>)
    }
}
