import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class AppDrawerChildren extends Component {
    static propTypes = {
        handleClose: PropTypes.func
    }

    constructor() {
        super()
        this.renderChildren = this.renderChildren.bind(this)
    }

    renderChildren() {
        return React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                handleAppDrawerClose: this.props.handleAppDrawerClose
            })
        })
    }

    render() {
        return (
            <>
                {this.renderChildren()}
            </>
        )
    }
}
