import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getLanguages } from '../actions'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'


export class LanguageMenu extends Component {
    static propTypes = {
        languages: PropTypes.array,
        anchor: PropTypes.any.isRequired,
        open: PropTypes.bool,
        onSelect: PropTypes.func,
        selectedIds: PropTypes.array
    }

    static defaultProps = {
        open: false
    }


    state = {
        open: this.props.open,
        anchorEl: null
    }



    componentDidMount() {
        this.updateLanguages();
    }

    componentDidUpdate() {
        this.updateLanguages();
    }



    updateLanguages() {
        if ((!this.props.languages)
            && (!this.props.loading)
            && (!this.props.error)
            && this.props.getLanguages)
            this.props.getLanguages();
    }

    handleAnchorClick(event) {
        console.log("Click");
        this.setState({
            anchorEl: event.currentTarget,
            open: !this.state.open
        })
    }

    handleClose(){
        this.setState({ open: false });
    }
    handleSelect(language){
        this.handleClose();
        if(this.props.onSelect)this.props.onSelect(language);
    }

    render() {
        const AnchorElement = this.props.anchor;
        return (
            <>
                <AnchorElement onClick={(event) => this.handleAnchorClick(event)} />
                <Menu
                    open={this.state.open}
                    anchorEl={this.state.anchorEl}
                    onClose={() => this.handleClose()}
                >
                    {this.props.loading && <CircularProgress />}
                    {this.props.languages && this.props.languages
                        .sort((a, b) => a.snippet.name.localeCompare(b.snippet.name))
                        .map(
                            (language) => {
                                return (
                                    <MenuItem
                                        key={language.id}
                                        onClick={()=>this.handleSelect(language)}
                                        selected={this.props.selectedIds && this.props.selectedIds.includes(language.id)}
                                    >
                                        {language.snippet.name}
                                    </MenuItem>)
                            }
                        )
                    }

                </Menu>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.youtube.languages.loading,
    languages: state.youtube.languages.list.items,
    error: state.youtube.languages.error
})

export default connect(
    mapStateToProps,
    { getLanguages }
)(LanguageMenu)
