import React, { Component } from 'react'
import PropTypes from 'prop-types'
import FormButtons from './FormButtons'

export default class FormPostButtons extends Component {
    static propTypes = {
        changed: PropTypes.bool,
        inProgress: PropTypes.bool,
        onSave: PropTypes.func,
        onReset: PropTypes.func,
        onPost: PropTypes.func
    }
    static defaultProps = {
        changed: false
    }


    render() {
        return (
                <FormButtons
                    onSave={this.props.onSave}
                    saveDisabled={(!this.props.changed) || (this.props.inProgress)}
                    onReset={this.props.onReset}
                    resetDisabled={(!this.props.changed) || (this.props.inProgress)}
                    onPost={this.props.onPost}
                    postDisabled={this.props.inProgress}
                    postCaption={(this.props.changed) ? "SAVE & POST" : "POST"}
                    inProgress={this.props.inProgress}
                >
                </FormButtons>
        )
    }
}
