import {
 YOUTUBE_GET_LANGUAGES,
 YOUTUBE_GET_LANGUAGES_SUCCESS,
 YOUTUBE_GET_LANGUAGES_FAILURE
} from './actionTypes'

import YouTubeClient from '../../../../apis/YouTube/YouTubeClient'


export function getLanguagesCall() {
    return {
        type: YOUTUBE_GET_LANGUAGES
    };
}


export function getLanguagesSuccess(response) {
    return {
        type: YOUTUBE_GET_LANGUAGES_SUCCESS,
        successResponse: response
    };
}

export function getLanguagesFailure(errorResponse) {
    return {
        type: YOUTUBE_GET_LANGUAGES_FAILURE,
        errorResponse: errorResponse,
    };
}

export function getLanguages() {
    return function (dispatch) {
        dispatch(getLanguagesCall());
        YouTubeClient.getLanguages()
            .then(
                (youtubeResponse) => dispatch(getLanguagesSuccess(youtubeResponse)),
                (errorResponse) => dispatch(getLanguagesFailure(errorResponse))
            ).catch(
                (errorResponse) => dispatch(getLanguagesFailure(errorResponse))
            );
    }
}