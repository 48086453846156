import {  
    GOOGLE_SIGNOUT_CALL, 
    GOOGLE_SIGNOUT_SUCCESS, 
    GOOGLE_SIGNOUT_FAILURE, 
    GOOGLE_SIGNIN_CALL, 
    GOOGLE_SIGNIN_SUCCESS, 
    GOOGLE_SIGNIN_FAILURE 
} from '../actions/actionTypes'

const initialState = {
    isSignedIn: false,
    loading: false,
    clientLoaded: false,
    clientReady: false
};


export default function defaultReducer(state = initialState, action) {
    switch (action.type) {
        case GOOGLE_SIGNIN_CALL:
        case GOOGLE_SIGNIN_SUCCESS:
        case GOOGLE_SIGNIN_FAILURE:
        case GOOGLE_SIGNOUT_CALL:
        case GOOGLE_SIGNOUT_SUCCESS:
        case GOOGLE_SIGNOUT_FAILURE:
            return {
                ...state,
                profile: action.profile,
                isSignedIn: action.isSignedIn,
                loading: action.loading
            }
        default:
            return state;
    }
}

