export const FACEBOOK_CLIENT_LOAD = "FACEBOOK_CLIENT_LOAD";
export const FACEBOOK_CLIENT_LOAD_SUCCESS = "FACEBOOK_CLIENT_LOAD_SUCCESS";
export const FACEBOOK_CLIENT_LOAD_FAILURE = "FACEBOOK_CLIENT_LOAD_FAILURE";

export const FACEBOOK_LOGIN = "FACEBOOK_LOGIN";
export const FACEBOOK_LOGOUT = "FACEBOOK_LOGOUT";

export const FACEBOOK_PROFILE_LOAD = "FACEBOOK_PROFILE_LOAD";
export const FACEBOOK_PROFILE_LOAD_SUCCESS = "FACEBOOK_PROFILE_LOAD_SUCCESS";
export const FACEBOOK_PROFILE_LOAD_FAILURE = "FACEBOOK_PROFILE_LOAD_FAILURE";

export const FACEBOOK_PROFILE_IMAGE_LOAD = "FACEBOOK_PROFILE_IMAGE_LOAD";
export const FACEBOOK_PROFILE_IMAGE_LOAD_FAILURE = "FACEBOOK_PROFILE_IMAGE_LOAD_FAILURE";
export const FACEBOOK_PROFILE_IMAGE_LOAD_SUCCESS = "FACEBOOK_PROFILE_IMAGE_LOAD_SUCCESS";

