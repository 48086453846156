import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar';
import Link from '@material-ui/core/Link';



const ChannelImageContainer = styled.div`
display: flex;
min-height: 70px !important;
flex-direction: row;
height: 70px; 
overflow: hidden;
background-image: ${props => 'url(' + props.src + ')'};
background-repeat: repeat-x;
background-position: center;
background-size: cover;
background-color: grey;
padding: 15px;
`;


const StyledAvatar = styled(Avatar)`
margin-top: auto;
`;

const ChildrenContainer = styled(Link)`
    margin-left: 1rem;
    font-size: 1.5rem;
    color: white !important;
    text-align: center;
    font-weight: bold;
    text-shadow: 1px 1px 2px black, 0 0 1em red;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

`;


export default class ChannelImage extends Component {
    static propTypes = {
        mobileUrl: PropTypes.string,
        tabletUrl: PropTypes.string,
        avatarUrl: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func
    }

    state = {
        image: null
    }

    constructor(props) {
        super(props);
        this.handleWindowResize = this.handleWindowResize.bind(this);
    }

    handleWindowResize() {
        var imageUrl = window.innerWidth >= 650 ? this.props.tabletUrl : this.props.mobileUrl;
        if (this.state.imageUrl !== imageUrl)
            this.setState({
                image: imageUrl
            });
    }

    componentDidMount() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowResize);
    }

    handleClick(video, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (this.props.onClick) this.props.onClick(video);
    }

    render() {
        return (
            <ChannelImageContainer src={this.state.image}>
                <StyledAvatar src={this.props.avatarUrl} />
                { this.props.children &&
                    <ChildrenContainer
                        href={this.props.href}
                        onClick={(event) => this.handleClick(this.props.video, event)}
                        underline="none"
                    >{this.props.children}</ChildrenContainer>
                }
            </ChannelImageContainer>
        )
    }
}
