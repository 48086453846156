const CLIENT_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const NONCE = process.env.REACT_APP_FACEBOOK_APP_NONCE;


const FB_SDK_SCRIPT_ID = "facebook-jssdk";
const API_VERSION = 'v8.0'

class FBClient {

    isApiLoaded() {
        return (document.getElementById(FB_SDK_SCRIPT_ID) !== null);
    }

    _loadCb(callback, errorCallBack) {
        console.log('Loadding FB API')
        const script = document.createElement('script');
        script.id = FB_SDK_SCRIPT_ID;
        script.async = true;
        script.defer = true;
        script.crossOrigin = 'annonymous';
        script.nonce = NONCE;
        script.src = 'https://connect.facebook.net/en_EN/sdk.js#xfbml=1&version=' + API_VERSION + '&autoLogAppEvents=1&appId=' + CLIENT_ID;
        script.onerror = () => { if (errorCallBack) errorCallBack() };
        window.fbAsyncInit = () => {
            window.FB.init({
                appId: CLIENT_ID,
                cookie: true,                     // Enable cookies to allow the server to access the session.
                xfbml: true,                     // Parse social plugins on this webpage.
                version: API_VERSION
            });
            console.log('FB APi Loaded');
            if (callback) callback();
        }
        document.body.appendChild(script);
    }

    _loadIfNotExists(callback, errorCallBack) {
        if (this.isApiLoaded()) {
            if (callback) callback();
        } else this._loadCb(callback, errorCallBack);
    }

    load() {
        return new Promise((resolve, reject) => {
            this._loadIfNotExists(resolve, reject);
        })
    }

    subscribeAuthStatusChange(callback) {
        this.load().then(() => {
            window.FB.Event.subscribe('auth.statusChange', callback);
        });
    }

    login() {
        return new Promise((resolve, reject) => this.load().then(
            () => window.FB.login((response) => {
                if(response.status==='connected')resolve(response);
                else reject(response);
            }),
            () => reject()
        ));
    }

    logout() {
        return new Promise((resolve, reject) => this.load().then(
            () => window.FB.logout((response) => resolve(response)),
            () => reject()
        ));
    }

    getLoginStatus(callback) {
        this.load().then(
            () => {
                window.FB.getLoginStatus(function (response) {
                    if (callback) callback(response);
                })
            }
        );
    }

    getProfile() {
        return new Promise(
            (resolve, reject) => {
                this.load().then(
                    () => window.FB.api('/me', { fields: 'name,id' }, function (response) {
                        if (response.error) reject(response.error);
                        else resolve(response);
                    })
                    ,
                    () => reject()

                );
            }
        );
    }

    getProfileImage() {
        return new Promise(
            (resolve, reject) => {
                this.load().then(
                    () => window.FB.api(
                        '/me/picture',
                        'GET',
                        {redirect:false},
                        function (response) {
                            if (response.error)reject(response.error);
                            else resolve(response);
                        }
                    )
                    ,
                    () => reject()

                );
            }
        );
    }
}

const FBClinetInstance = new FBClient();

export default FBClinetInstance;