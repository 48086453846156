import {
    YOUTUBE_GET_LANGUAGES,
    YOUTUBE_GET_LANGUAGES_SUCCESS,
    YOUTUBE_GET_LANGUAGES_FAILURE
} from '../actions/actionTypes'




import { reduceError } from '../../../../utils'
import { getAppErrorFromErrorResponse } from '../../../google/utils'

const initialState = {
    loading: false,
    error: null,
    list: {
        items: null
    }
};


export default function defaultReducer(state = initialState, action) {
    state = reduceError(state, action);
    switch (action.type) {
        case YOUTUBE_GET_LANGUAGES:
            return {
                ...state,
                loading: true
            }
        case YOUTUBE_GET_LANGUAGES_SUCCESS:
            return {
                ...state,
                loading: false,
                list: {
                    items: action.successResponse.result.items,
                }
          }
        case YOUTUBE_GET_LANGUAGES_FAILURE:
            return {
                ...state,
                loading: false,
                error: getAppErrorFromErrorResponse('Failed to get languages',action.errorResponse)
            }
        default:
            return state;
    }
}

