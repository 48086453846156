import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import VKLogo from '@vkontakte/icons/dist/24/logo_vk';


import {
    vkDoLogin,
} from '../actions'

import SocialLoginButton from '../../../components/SocialLoginButton';

export class VKLoginButton extends Component {
    static propTypes = {
        vkDoLogin: PropTypes.func.isRequired,
    }

    render() {
        return (
            <SocialLoginButton
                name="VKontakte"
                icon={VKLogo}
                onLogin={this.props.vkDoLogin}
                backgoroundColor='#4680C2'
                backgoroundColorHover='#71A1D8'
                color="white"
                className={this.props.className}

            />
        )
    }
}


const mapDispatchToProps = {
    vkDoLogin,
}

export default connect(null, mapDispatchToProps)(VKLoginButton)
