import React, { Component } from 'react'
import VideoItem, { ThumbanailCell, TitleTableCell } from './VideoItem';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { getMyVideos } from '../actions';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow'; 
import TableCell from '@material-ui/core/TableCell'; 
import TableContainer from '@material-ui/core/TableContainer'; 
import TablePagination from '@material-ui/core/TablePagination'; 
import TableFooter from '@material-ui/core/TableFooter';
import SearchContainer from '../../../SearchContainer';
import PageTitle from '../../../PageTitle';
import styled from 'styled-components';



const StyledTableContainer = styled(TableContainer)`
padding: 15px;
text-align: left;
`;

const StyledFooter = styled(TableFooter)`
overflow: hidden;
padding:0;
`;

const StyledFooterCell = styled(TableCell)`
overflow: hidden;
padding:0 !important;
`;

const StyledPagination = styled(TablePagination)`
overflow: hidden;
padding:0 !important;
.pagination-toolbar{
    overflow: hidden;
    padding:0 !important;
};
.margin-right-reduce {
    margin-right: 8px;
};
.margin-left-reduce {
    margin-left: 8px;
};
`;


class VideoList extends Component {
    static propTypes = {
        onVideoClick: PropTypes.func
    };

    state = {
        term: ''
    }

    constructor(props) {
        super(props);
        this.updateChannel = this.updateChannel.bind(this);
        this.search = this.search.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
        this.handleMaxResultsChange = this.handleMaxResultsChange.bind(this);
    }

    updateChannel(term, pageToken, page = 0, maxResults = 10) {
        if ((!this.props.error)&&(!this.props.loading)) {
            this.props.getMyVideos( maxResults, term, pageToken, page);
        }
    }

    getVideos(){
        if (!this.props.list.items)
            this.updateChannel(this.props.list.term, this.props.list.pageToken, this.props.list.page, this.props.list.resultsPerPage);
    }

    componentDidMount() {
        console.log('Video list did mount',this.props);
        this.getVideos();
    }

    componentDidUpdate() {
        console.log('Video list did update',this.props);
        this.getVideos();
    }

    search(term) {
        this.updateChannel(term, null, null, this.props.list.resultsPerPage);
    }

    handleClick(video) {
        if (this.props.onVideoClick) this.props.onVideoClick(video);
        this.props.history.push(this.props.location.pathname + "/" + video.id);
    }

    handlePageChange(event, page) {
        var token = (page > this.props.list.page) ? this.props.list.nextPageToken : this.props.list.prevPageToken;
        this.updateChannel(this.props.list.term, token, page, this.props.list.resultsPerPage);
    }

    handleMaxResultsChange(event) {
        this.updateChannel(this.props.list.term, null, null, event.target.value);
    }

    render() {
        const maxResults = (this.props.list.resultsPerPage) ? this.props.list.resultsPerPage : 10;
        const results = (this.props.list.totalResults) ? this.props.list.totalResults : 0;
        const page = (this.props.list.page) ? this.props.list.page : 0;
        //if(!this.props.channel)return (<Skeleton variant='rect'/>);
        return (
            <StyledTableContainer>
                <PageTitle text='My Videos' />
                <SearchContainer onSearch={this.search} loading={this.props.loading} term={this.props.list.term} />
                <Table size='small' style={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <ThumbanailCell>Image</ThumbanailCell>
                            <TitleTableCell>Title/Description
                            </TitleTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.list.items && this.props.list.items.map((video) => (
                            <VideoItem 
                                key={video.id} 
                                video={video} 
                                onClick={(video) => this.handleClick(video)} 
                                href={this.props.location.pathname + "/" + video.id}
                                />
                        ))}
                    </TableBody>
                    <StyledFooter>
                        <TableRow>
                        <StyledFooterCell colSpan={2}>
                            <StyledPagination
                                component='div'
                                rowsPerPageOptions={[10, 25, 50]}
                                count={results}
                                labelRowsPerPage=''
                                rowsPerPage={maxResults}
                                labelDisplayedRows={({ from, to, count }) => (count<100000)?(from+'-'+to+' of '+count):(from+'-'+to)}
                                page={page}
                                onChangePage={this.handlePageChange}
                                onChangeRowsPerPage={this.handleMaxResultsChange}
                                classes={{
                                    toolbar: 'pagination-toolbar',
                                    selectRoot: 'margin-right-reduce',
                                    actions: 'margin-left-reduce'
                                }}
                            />
                        </StyledFooterCell>
                        </TableRow>
                    </StyledFooter>
                </Table>
            </StyledTableContainer>
        )
    }
}

const youtubeVideosMap = (state) => {
    return {
        channel: state.youtube.channel.data,
        isSignedIn: state.google.user.isSignedIn,
        loading: state.youtube.videos.loading,
        selected: state.youtube.videos.selected,
        list: state.youtube.videos.list,
        error: state.youtube.videos.error,
        videoLoading: state.youtube.videos.videoLoading
    };
}

export default connect(
    youtubeVideosMap,
    { getMyVideos }
)(VideoList)