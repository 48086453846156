import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from '@material-ui/core/Avatar'
import Badge from '@material-ui/core/Badge';
import styled from 'styled-components';


const BadgeContainer = styled.div`
width: 22px;
height: 22px;
color: ${props => (props.badgecolor) ? props.badgecolor : 'black'};
background-color: white;
`;

export default class SocialAvatar extends Component {
    static propTypes = {
        src: PropTypes.string,
        badge: PropTypes.any,
        badgeColor: PropTypes.string,
    }

    getIcon(props) {
        const Icon = this.props.badge;
        if (!Icon) return (<div />);
        return (<Icon {...props} />);
    }


    render() {
        return (
            <Badge
                overlap="circle"
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                badgeContent={
                    <BadgeContainer badgecolor={this.props.badgeColor}>
                        {this.getIcon({ 
                            size: "small", 
                            fontSize: "small",
                            width: 20,
                            height: 20
                            })}
                    </BadgeContainer>}
            >
                <Avatar src={this.props.src} />
            </Badge>
        )
    }
}

