import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import VKLoginButton from './VKLoginButton'
import {
    vkClientLoad
} from '../actions'
import VKProfile from './VKProfile'
import SocialWidget from '../../../components/SocialWidget'

export class VKSocialWidget extends Component {
    static propTypes = {
        isSignedIn: PropTypes.bool,
        vkClientLoad: PropTypes.func
    }

    componentDidMount(){
        if(this.props.vkClientLoad)this.props.vkClientLoad();
    }

    render() {
        return (

            <SocialWidget 
                loginButton={VKLoginButton} 
                profileBadge={VKProfile}
                isSignedIn={this.props.isSignedIn}
                className={this.props.className}
                />
        )
    }
}

const mapStateToProps = (state) => ({
    isSignedIn: state.social.vkontakte.isSignedIn,
})

const mapDispatchToProps = {
    vkClientLoad    
}

export default connect(mapStateToProps, mapDispatchToProps)(VKSocialWidget)
