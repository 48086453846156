
class GoogleClient {
  #API_KEY = null;
  #CLIENT_ID = null;
  #SCOPES = null;
  #LIBRARIES = null;
  #TIMEOUT = 30000;

  #loadPromise = null;
  #initPromise = null;

  #GoogleAuth = null;

  constructor(apiKey, clientId, scopes, clientLibaries, timeout = 30000) {
    this.#API_KEY = apiKey;
    this.#CLIENT_ID = clientId;
    this.#SCOPES = scopes;
    this.#LIBRARIES = clientLibaries;
    this.#TIMEOUT = timeout;
  }

  _getLoadPromise() {
    if ((window.gapi.client)
      && (window.gapi.auth2)
      && (window.gapi.signin2)) {
      return Promise.resolve('Already loaded');
    }
    return new Promise((resolve, reject) => {
      window.gapi.load(this.#LIBRARIES, {
        callback: resolve,
        onerror: () => {
          console.log('Client load failed');
          this.#loadPromise = null;
          reject({
            details: "Failed to download Google client Library"
          })
        },
        timeout: this.#TIMEOUT,
        ontimeout: () => {
          console.log('Client load timeout');
          this.#loadPromise = null;
          reject({
            details: "Failed to download Google client Library timely"
          })
        }
      });
    });
  }

  load() {
    if (!window.gapi) return Promise.reject({
      details: "GAPI not loaded"
    });
    if (!this.#loadPromise) {
      this.#loadPromise = this._getLoadPromise();
    }
    return this.#loadPromise;
  }


  _getInitPromise(callback) {
    if (this.#GoogleAuth) {
      if (callback) this.#GoogleAuth.isSignedIn.listen(callback);
      return Promise.resolve(this.#GoogleAuth);
    }
    return this.load().then(() => {
      return window.gapi.client.init({
        apiKey: this.#API_KEY,
        clientId: this.#CLIENT_ID,
        scope: this.#SCOPES
      }).then(
        () => {
          this.#GoogleAuth = window.gapi.auth2.getAuthInstance();
          if (callback) {
            this.#GoogleAuth.isSignedIn.listen(callback);
          }
        },
        (error) => {
          this.#initPromise = null;
          console.log('Error init', error);
        }
      );
    },
      (error) => {
        this.#initPromise = null;
        return {
          details: "Client not loaded",
          cause: error
        }
      }
    );
  }

  init(callback) {
    if (!this.#initPromise) {
      this.#initPromise = this._getInitPromise(callback);
    }
    if (callback) {
      this.#initPromise = this.#initPromise.then(() => {
        this.#GoogleAuth.isSignedIn.listen(callback);
      });
    }
    return this.#initPromise;
  }

  signIn() {
    this.init().then(() => { return this.#GoogleAuth.signIn() });
  }

  signOut() {
    this.init().then(() => { return this.#GoogleAuth.signOut() });
  }

  getBasicProfile() {
    const GoogleAuth = window.gapi.auth2.getAuthInstance();
    var user = GoogleAuth.currentUser.get();
    if (!user) return null;
    return user.getBasicProfile();
  }


  renderButton(divId, onSuccess, onFailure, theme = 'dark', width = 192, height = 40, scopes = this.#SCOPES) {
    this.init().then(() =>{
      console.log('render',divId);
      window.gapi.signin2.render(divId, {
        'scope': scopes,
        'width': width,
        'height': height,
        'longtitle': true,
        'theme': theme,
        onsuccess: onSuccess,
        onfailure: onFailure
      });
    });
  }

}

const CLIENT_LOAD_STRING = 'client:auth2';
//const CLIENT_LOAD_STRING = 'client:auth2:sissy';
//const CLIENT_LOAD_STRING = 'sisy';
const CLIENT_LOAD_TIMEOUT = 30000;
//const CLIENT_LOAD_TIMEOUT = 10000;
var CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
var SCOPES = process.env.REACT_APP_GOOGLE_SCOPES;


const GoogleClientInstane = new GoogleClient(null, CLIENT_ID, SCOPES, CLIENT_LOAD_STRING, CLIENT_LOAD_TIMEOUT);

export default GoogleClientInstane;
