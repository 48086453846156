import {
    YOUTUBE_GET_VIDEOS,
    YOUTUBE_GET_VIDEOS_SUCCESS,
    YOUTUBE_GET_VIDEOS_FAILURE
} from './actionTypes';

import YouTubeClient from '../../../../apis/YouTube/YouTubeClient'

export function getVideosCall() {
    return {
        type: YOUTUBE_GET_VIDEOS
    };
}


export function getVideosSuccess(response,term = '', pageToken = null, page=0) {

    YouTubeClient.normalizeId(response.result.items);
    return {
        type: YOUTUBE_GET_VIDEOS_SUCCESS,
        successResponse: response,
        pageToken: pageToken,
        page: page,
        term: term
    };
}

export function getVideosFailure(errorResponse) {
    return {
        type: YOUTUBE_GET_VIDEOS_FAILURE,
        errorResponse: errorResponse,
    };
}

export function getMyVideos(maxResults = 10, term = '',  pageToken = null, page = 0) {
    return function (dispatch) {
        dispatch(getVideosCall());
        YouTubeClient.getMyVideos(term, pageToken, maxResults)
            .then(
                (youtubeResponse) => dispatch(getVideosSuccess(youtubeResponse, term, pageToken, page)),
                (errorResponse) => dispatch(getVideosFailure(errorResponse))
            ).catch(
                (errorResponse) => dispatch(getVideosFailure(errorResponse))
            );
    }
}

export function getVideosForChannel(channelId, maxResults = 10, term = '',  pageToken = null, page = 0) {
    return function (dispatch) {
        dispatch(getVideosCall());
        YouTubeClient.getVideosForChannel(channelId, term, pageToken, maxResults)
            .then(
                (youtubeResponse) => dispatch(getVideosSuccess(youtubeResponse, term, pageToken, page)),
                (errorResponse) => dispatch(getVideosFailure(errorResponse))
            ).catch(
                (errorResponse) => dispatch(getVideosFailure(errorResponse))
            );
    }
}