import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FBProfile from './FBProfile'
import FBLoginButton from './FBLoginButton'

import {
    fbClientLoad,

} from '../actions'
import SocialWidget from '../../../components/SocialWidget'

export class FBSocialWidget extends Component {
    static propTypes = {
        isSignedIn: PropTypes.bool
    }

    componentDidMount() {
        if (this.props.fbClientLoad) this.props.fbClientLoad();
    }


    render() {
        return (
            <SocialWidget
                loginButton={FBLoginButton}
                profileBadge={FBProfile}
                isSignedIn={this.props.isSignedIn}
                className={this.props.className}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    isSignedIn: state.social.facebook.isSignedIn
})

const mapDispatchToProps = {
    fbClientLoad
}

export default connect(mapStateToProps, mapDispatchToProps)(FBSocialWidget)
