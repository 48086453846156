import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChannelImage from '../../channel/components/ChannelImage'


export default class ChannelListItem extends Component {
    static propTypes = {
        channel: PropTypes.object.isRequired,
        href: PropTypes.string,
        onClick: PropTypes.func
    }

    render() {
        return (

            <ChannelImage
                mobileUrl={this.props.channel.brandingSettings.image.bannerMobileImageUrl}
                tabletUrl={this.props.channel.brandingSettings.image.bannerTabletImageUrl}
                avatarUrl={this.props.channel.snippet.thumbnails.default.url}
                href={this.props.href}
                onClick={this.props.onClick}
            >
                {this.props.channel.snippet.title}
            </ChannelImage>
        )
    }
}
