import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Facebook from '@material-ui/icons/Facebook';
import {
    fbGetProfile,
    fbDoLogout
} from '../actions'
import { Profile } from '../../../components/Profile';


export class FBProfile extends Component {
    static propTypes = {
        profile: PropTypes.object
    }

    updateProfile() {
        if ((this.props.isSignedIn) && (!this.props.profile) && (this.props.fbGetProfile)) {
            this.props.fbGetProfile();
        }

    }

    componentDidMount() {
        this.updateProfile();
    }

    componentDidUpdate() {
        this.updateProfile();
    }


    render() {
        return (
            <Profile 
                badge={Facebook} 
                badgeColor='#1877F2' 
                name={this.props.name} 
                onSignOut={this.props.fbDoLogout}
                avatar={this.props.avatar}
                className={this.props.className}
            />
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.social.facebook.profile, 
    name: state.social.facebook.profile && state.social.facebook.profile.name,
    avatar: state.social.facebook.profile && state.social.facebook.profile.image && state.social.facebook.profile.image.url,
    isSignedIn: state.social.facebook.isSignedIn
})


const mapDispatchToProps = {
    fbGetProfile,
    fbDoLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(FBProfile)
