import {
    YOUTUBE_GET_CHANNELS,
    YOUTUBE_GET_CHANNELS_SUCCESS,
    YOUTUBE_GET_CHANNELS_FAILURE,
} from '../actions/actionTypes'

import {
    GOOGLE_SIGNOUT_SUCCESS,
    GOOGLE_SIGNOUT_FAILURE,
    GOOGLE_SIGNIN_FAILURE
} from '../../../google/user/actions/actionTypes'


import { reduceError } from '../../../../utils'


const initialState = {
    loading: false,
    list: {
        page: null,
        pageToken: null,
        nextPageToken: null,
        prevPageToken: null,
        totalResults: null,
        term: '',
        items: null
    }
};


export default function defaultReducer(state = initialState, action) {
    state=reduceError(state,action);
    switch (action.type) {
        case GOOGLE_SIGNIN_FAILURE:
        case GOOGLE_SIGNOUT_SUCCESS:
        case GOOGLE_SIGNOUT_FAILURE:
            return initialState;
        case YOUTUBE_GET_CHANNELS:
            return {
                ...state,
                loading: true,
                error: null
            }
        case YOUTUBE_GET_CHANNELS_SUCCESS:
            var pageTokens=state.list.pageTokens;
            if(!pageTokens)pageTokens=[];
            if(action.page){
                pageTokens[0]=null;
                pageTokens[action.page]=action.pageToken;
            }else pageTokens=null;

            var prevPageToken=action.successResponse.result.prevPageToken;
            if((!prevPageToken)&&(action.page)&&(pageTokens)){
                prevPageToken=(pageTokens[action.page-1])?pageTokens[action.page-1]:null;
            }
            return {
                ...state,
                loading: false,
                list: {
                    pageTokens: pageTokens,
                    items: action.successResponse.result.items,
                    totalResults: action.successResponse.result.pageInfo.totalResults,
                    resultsPerPage: action.successResponse.result.pageInfo.resultsPerPage,
                    nextPageToken: action.successResponse.result.nextPageToken,
                    prevPageToken: prevPageToken,
                    pageToken: action.pageToken,
                    page: action.page,
                    term: action.term
                }

            }
        case YOUTUBE_GET_CHANNELS_FAILURE:
            return {
                ...state,
                loading: false,
                error: {
                    message: 'Failed to get channels',
                    details: action.errorResponse,
                    causeMessage: action.errorResponse.result.error.message,
                    causeCode: action.errorResponse.result.error.code
                }

            }
        default:
            return state;
    }
}
