import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import cloneDeep from 'lodash/cloneDeep';
import styled from 'styled-components';
import DelayTextField from '../../../controls/DelayTextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Delete from '@material-ui/icons/Delete';
import Add from '@material-ui/icons/Add';
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LanguageMenu from './LanguageMenu';

const LocContainer = styled.div`
display: flex;
flex-direction: column;
&> * {
  margin-top: 10px;
  margin-bottom: 10px;
};
`;


const TabsContainer = styled.div`
display: grid;
flex-direction: row;
width: 100%;
max-width: 100%;
overflow: none;
grid-template-columns: 1fr 48px;
`;

const StyledTabs = styled(Tabs)`
`;

const StyledFormControl = styled(FormControl)`
&> * {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
};
`;


const AddHint=styled.div`
  margin-left: auto;
  margin-right: 0px;
  margin-top: 12px;
  margin-bottom: 12px;
`;

const StyledButton=styled(Button)`
  margin-left: auto;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
`;

export class LocalizationsEditor extends Component {
  static propTypes = {
    localizations: PropTypes.object,
    onChange: PropTypes.func
  }

  state = {
    selectedLocale: null,
  }


  updateExistingLanguages() {
    if (
      ((this.state.selectedLocale === null)||(!this.getLocaleKeysSafe().includes(this.state.selectedLocale)))
      && (this.props.localizations)
      && (Object.keys(this.props.localizations).length > 0)
    ) {
      this.setState({
        selectedLocale: Object.keys(this.props.localizations)[0]
      })
    }
  }


  componentDidMount() {
    this.updateExistingLanguages();
  }

  componentDidUpdate() {
    this.updateExistingLanguages();
  }

  postUpdated(localizations) {
    if (this.props.onChange) this.props.onChange(localizations);
  }

  getLocaleKeysSafe(){
    var localeKeys=this.getLocaleKeys();
    return (localeKeys)?localeKeys:[];
  }

  getLocaleKeys(){
    var localeKeys=(this.props.localizations)?Object.keys(this.props.localizations):null;
    if((localeKeys)&&(localeKeys.length<1))localeKeys=null;
    return localeKeys;
  }

  removeLanguage(language) {
    var newLocalizations = cloneDeep(this.props.localizations);
    delete newLocalizations[language];
    this.postUpdated(newLocalizations);
  }

  changeTitle(event, locale, title) {
    this.updateSelected(locale, title);
  }

  changeDescription(event, locale, description) {
    this.updateSelected(locale, null, description);
  }

  selectLanguage(language) {
    var languageId = (language) ? language.id : null;
    var newLocalizations = (this.props.localizations) ? cloneDeep(this.props.localizations) : {};
    if (languageId  && !Object.keys(newLocalizations).includes(languageId)) {
      newLocalizations[languageId] = {
        title: '',
        description: ''
      };
      this.postUpdated(newLocalizations);
    }
    this.setState({
      selectedLocale: languageId,
    });
  }

  updateSelected(locale, title, description) {
    if (locale && ((title) || (description))) {
      var newLocalizations = (this.props.localizations) ? cloneDeep(this.props.localizations) : {};
      if (title) newLocalizations[locale].title = title;
      if (description) newLocalizations[locale].description = description;
      this.postUpdated(newLocalizations);
    }
  }

  getLanguageName(locale) {
    var language = this.getLanguage(locale);
    if (!language) return locale;
    return language.snippet.name;
  }

  getLanguage(locale) {
    if (!this.props.languages) return null;
    var found = this.props.languages.filter((language) => language.id === locale);
    if (!found) return null;
    if (found.length < 1) return null;
    return found[0];
  }

  handleLocaleChange(locale) {
    this.setState({
      selectedLocale: locale
    });
  }

  getSelectedLocale(keys){
    if(!this.state.selectedLocale){
      return (keys)?keys[0]:null;
    }
    return (keys&&keys.includes(this.state.selectedLocale))?this.state.selectedLocale:keys[0];
  }

  render() {
    var localeKeys=this.getLocaleKeys();
    return (
      <LocContainer variant={this.props.variant} className={this.props.className}>
        <TabsContainer>
          {!localeKeys && <AddHint>No localizations. Use this button to add -&gt;</AddHint>}
          {localeKeys && (
          <StyledTabs
            value={this.getSelectedLocale(localeKeys)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            onChange={(event, value) => this.handleLocaleChange(value)}
          >
            { localeKeys.map((locale, index) => {
              return (
                <Tab
                  id={`scrollable-auto-tab-${index}`}
                  key={locale}
                  value={locale}
                  label={this.getLanguageName(locale)}
                  index={index}
                />
              );
            })}
          </StyledTabs>
          )}
          <LanguageMenu
            anchor={(props) =>
              (<IconButton {...props} color="primary">
                <Add />
              </IconButton>)
            }
            onSelect={(language) => this.selectLanguage(language)}
            selectedIds={localeKeys}
          />
        </TabsContainer>
        {localeKeys && localeKeys.map((locale) => {
          return (
            <StyledFormControl
              variant={this.props.variant}
              hidden={this.state.selectedLocale !== locale}
              key={locale}
            >
              <DelayTextField
                variant={this.props.variant}
                label='Title'
                value={this.props.localizations[locale].title}
                onChange={(event) => this.changeTitle(event, locale, event.target.value)}
                inputProps={{ maxLength: 100 }}
              />
              <DelayTextField
                variant={this.props.variant}
                label='Description'
                multiline
                value={this.props.localizations[locale].description}
                onChange={(event) => this.changeDescription(event, locale, event.target.value)}
                inputProps={{ maxLength: 5000 }}
                />
                <StyledButton
                  startIcon={<Delete/>}
                  onClick={()=>this.removeLanguage(locale)}
                >
                  Delete Localization
                </StyledButton>

            </StyledFormControl>
          );
        })}

      </LocContainer >
    )
  };
}

const mapStateToProps = (state) => ({
  languages: state.youtube.languages.list.items
})


export default connect(mapStateToProps)(LocalizationsEditor);