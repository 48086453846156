import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';

const StyledLink = styled(Link)`
text-decoration: inherit !important;
`;

const VideoTableRow = styled(TableRow)`
cursor: pointer;
`;

export const ThumbanailCell = styled(TableCell)`
width: 80px !important;
min-width: 80px;
max-width: 80px;
flex-wrap: nowrap;
font-weight: normal;
font-size: 1rem;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
margin: 0px;
padding: 0px;
`;



export const TitleTableCell = styled(TableCell)`
flex-wrap: nowrap;
font-weight: normal;
font-size: 1rem;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
vertical-align: top;
`;

const VideoThumbnail = styled.img`
height: 55px;
padding-left: 0px;
padding-right: 0px;
margin-top: 2px;
margin-bottom: 2px;
`;

const VideoTitle = styled.div`
width: 100%;
display: block;
position: relative;
border-collapse: separate;
flex-wrap: nowrap;
font-weight: bolder;
font-size: 1rem;
white-space: nowrap;
text-overflow: ellipsis !important;
overflow: hidden;
`;

const VideoDescription = styled.div`
width: 100%;
display: block;
position: relative;
border-collapse: separate;
flex-wrap: nowrap;
font-weight: lighter;
font-size: 1rem;
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
`;

export default class VideoItem extends Component {
    static propTypes = {
        video: PropTypes.object.isRequired,
        onClick: PropTypes.func,
        selected: PropTypes.bool
    }

    handleClick(video, event) {
        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        if (this.props.onClick) this.props.onClick(video);
    }
    //                  

    render() {
        const parser = new DOMParser();
        const title = parser.parseFromString(`<!doctype html><body>${this.props.video.snippet.title}`, 'text/html').body.textContent;
        const description = parser.parseFromString(`<!doctype html><body>${this.props.video.snippet.description}`, 'text/html').body.textContent;
        return (
            <VideoTableRow
                hover={true}
                onClick={(event) => this.handleClick(this.props.video, event)}
            >
                <ThumbanailCell>
                    <StyledLink
                        href={this.props.href}
                        onClick={(event) => this.handleClick(this.props.video, event)}
                        underline="none"
                        color="inherit"
                    >
                        <VideoThumbnail src={this.props.video.snippet.thumbnails.default.url} alt={title} />
                    </StyledLink>
                </ThumbanailCell>
                <TitleTableCell>
                    <StyledLink
                        href={this.props.href}
                        onClick={(event) => this.handleClick(this.props.video, event)}
                        underline="none"
                        color="inherit"
                    >
                        <VideoTitle>{title}</VideoTitle>
                        <VideoDescription>{description}</VideoDescription>
                    </StyledLink>
                </TitleTableCell>
            </VideoTableRow >
        )
    }
}
