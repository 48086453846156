
const CLIENT_ID = process.env.REACT_APP_VK_APP_ID;

const VK_SDK_URL = "https://vk.com/js/api/openapi.js?168";

const VK_SDK_SCRIPT_ID = "vk-jssdk";

const VK_SDK_VERSION = "5.124";

const VK_WALL_SCOPE = 8192;
const VK_GROUPS_SCOPE = 262144;

class VKClient {

    isApiLoaded() {
        return (document.getElementById(VK_SDK_SCRIPT_ID) !== null);
    }


    _loadCb(callback, errorCallBack) {
        const script = document.createElement('script');
        script.id = VK_SDK_SCRIPT_ID;
        script.async = true;
        script.defer = true;
        script.src = VK_SDK_URL;
        script.onerror = () => { if (errorCallBack) errorCallBack() };
        window.vkAsyncInit = () => {
            window.VK.init({
                apiId: CLIENT_ID
            });
            if (callback) callback();
        }
        document.body.appendChild(script);
    }

    _loadIfNotExists(callback, errorCallBack) {
        if (this.isApiLoaded()) {
            if (callback) callback(true);
        } else this._loadCb(callback, errorCallBack);
    }

    load() {
        return new Promise((resolve, reject) => {
            this._loadIfNotExists(resolve, reject);
        })
    }

    subscribeAuthStatusChange(callback) {
        this.load().then(
            () => {
                console.log("Subscribe!!!");
                window.VK.Observer.subscribe("auth.statusChange", () => window.VK.Auth.getLoginStatus(callback));
            }
        );
    }


    getLoginStatus() {
        return new Promise((resolve, reject) => {
            this.load().then(
                () => window.VK.Auth.getLoginStatus(
                    (data) => {
                        if (data.error) reject(data);
                        else resolve(data)
                    }),
                reject
            );
        });
    }

    getCurrentProfile() {
        return new Promise((resolve, reject) => {
            console.log("users.get");
            this.load().then(
                () => window.VK.api(
                    "users.get",
                    {
                        "fields": "id,first_name,last_name,photo_50",
                        "v": VK_SDK_VERSION
                    },

                    (data) => {
                        console.log("data",data);
                        if (data.error) reject(data);
                        else resolve(data.response[0])
                    }),
                () => reject()
            );
        });
    }


    login() {
        return new Promise((resolve, reject) => {
            this.load().then(
                () => window.VK.Auth.login(
                    (data) => {
                        if (data.error) reject(data);
                        else if (data.status === 'connected') resolve(data);
                        else reject(data);
                    },
                    VK_WALL_SCOPE + VK_GROUPS_SCOPE
                ),
                reject
            );
        });
    }

    logout() {
        return new Promise((resolve, reject) => {
            this.load().then(
                () => window.VK.Auth.logout(
                    (data) => {
                        if (data.error) reject(data);
                        else resolve(data)
                    },
                ),
                reject
            );
        });
    }

}

const VKClinetInstance = new VKClient();

export default VKClinetInstance;