import { APP_DISMISS_ERROR, APP_DISMISS_ALL_ERRORS, APP_RESET } from './actionTypes';


export function appDismissError(error) {
    return {
        type: APP_DISMISS_ERROR,
        error
    };
}

export function appDismissAllErrors(error) {
    return {
        type: APP_DISMISS_ALL_ERRORS,
    };
}

export function appReset() {
    return {
        type: APP_RESET,
    };
}