import {
    YOUTUBE_GET_VIDEO,
    YOUTUBE_GET_VIDEO_SUCCESS,
    YOUTUBE_GET_VIDEO_FAILURE,
    YOUTUBE_UPDATE_VIDEO,
    YOUTUBE_SELECT_VIDEO,
    YOUTUBE_RESET_VIDEO,
    YOUTUBE_SAVE_VIDEO,
    YOUTUBE_SAVE_VIDEO_SUCCESS,
    YOUTUBE_SAVE_VIDEO_FAILURE,
} from './actionTypes'

import YouTubeClient from '../../../../apis/YouTube/YouTubeClient'

export function selectVideo(video) {
    return {
        type: YOUTUBE_SELECT_VIDEO,
        data: video
    };
}



export function updateVideo(property, value) {
    return {
        type: YOUTUBE_UPDATE_VIDEO,
        property: property,
        value: value
    };
}

export function getVideoCall() {
    return {
        type: YOUTUBE_GET_VIDEO
    };
}


export function getVideoSuccess(response) {
    return {
        type: YOUTUBE_GET_VIDEO_SUCCESS,
        successResponse: response
    };
}

export function getVideoFailure(errorResponse) {
    return {
        type: YOUTUBE_GET_VIDEO_FAILURE,
        errorResponse: errorResponse
    };
}

export function resetVideo(){
    return {
        type: YOUTUBE_RESET_VIDEO,
    }
    
}

export function getVideo(id) {
    return function (dispatch) {
        dispatch(getVideoCall());
        YouTubeClient.getVideoById(id)
            .then(
                (youtubeResponse) => {
                    dispatch(getVideoSuccess(youtubeResponse))
                    return youtubeResponse;
                },
                (errorResponse) => dispatch(getVideoFailure(errorResponse))
            ).then(
                (response) => {
                    if ((response.result) && (response.result.items) && (response.result.items.length > 0)) {
                        dispatch(selectVideo(response.result.items[0]));
                    }
                }
            )
            .catch(
                (errorResponse) => dispatch(getVideoFailure(errorResponse))
            );
    }
}

export function saveVideoCall() {
    return {
        type: YOUTUBE_SAVE_VIDEO
    };
}


export function saveVideoSuccess(response) {
    return {
        type: YOUTUBE_SAVE_VIDEO_SUCCESS,
        successResponse: response
    };
}

export function saveVideoFailure(errorResponse) {
    return {
        type: YOUTUBE_SAVE_VIDEO_FAILURE,
        errorResponse: errorResponse
    };
}


export function saveVideo(data) {
    return function (dispatch) {
        dispatch(saveVideoCall());
        YouTubeClient.saveVideoData(data)
            .then(
                (youtubeResponse) => {
                    dispatch(saveVideoSuccess(youtubeResponse))
                    return youtubeResponse;
                },
                (errorResponse) => dispatch(saveVideoFailure(errorResponse))
            )
            .catch(
                (errorResponse) => dispatch(saveVideoFailure(errorResponse))
            );
    }
}
