import GoogleClient from '../Google/GoogleClient'

var CHANNEL_PARTS = process.env.REACT_APP_YOUTUBE_CHANNEL_PARTS;
var VIDEO_PARTS = process.env.REACT_APP_YOUTUBE_VIDEO_PARTS;

function getIdFromIdObject(idObject) {
    switch (idObject.kind) {
        case 'youtube#channel':
            return idObject.channelId;
        case 'youtube#video':
            return idObject.videoId;
        default:
            return idObject;
    }
}


function normalizeItemId(item) {
    if (!item) return null;
    if (!item.id) return item;
    if (typeof item.id === 'object') {
        item.id = getIdFromIdObject(item.id);
    }
    return item;
}


function normalizeItemKind(item) {
    if (!item) return null;
    if (!item.id) return item;
    if (typeof item.id === 'object') {
        item.kind = item.id.kind;
    }
    return item;
}


function normalizeId(items) {
    if (!items) return null;
    if (Array.isArray(items)) {
        items = items
            .map((item) => {
                return normalizeItemKind(item);
            }).map((item) => {
                return normalizeItemId(item);
            });
    } else {
        items = normalizeItemId(items);
    }
    return items;
}

function getLanguages() {
    return load().then(
        () => {
            return window.gapi.client.youtube.i18nLanguages.list({ part: 'snippet' });
        }
    );
}

function getMyVideos(term = '', pageToken = null, maxResults = 10) {
    var params = {
        type: ['video'],
        part: ['snippet'],
        q: term,
        order: 'date',
        forMine: true,
        maxResults: maxResults,
        pageToken: pageToken,
    };


    return load().then(
        () => {
            return window.gapi.client.youtube.search.list(params);
        }
    );
}


function getVideosForChannel(channelId, term = '', pageToken = null, maxResults = 10) {
    var params = {
        type: ['video'],
        part: ['snippet'],
        q: term,
        order: 'date',
        maxResults: maxResults,
        channelId: channelId,
        prevPageToken: pageToken,
        pageToken: pageToken,
    };


    return load().then(
        () => {
            return window.gapi.client.youtube.search.list(params);
        }
    );
}


function saveChannelData(channel) {
    return new Promise((resolve, reject) => {
        load().then(
            () => {
                var params = {
                    part: [
                        "brandingSettings"
                    ],
                    resource: {
                        "id": channel.id,
                        "brandingSettings": channel.brandingSettings
                    }
                }
                return window.gapi.client.youtube.channels.update(params);
            },
            () => reject("Failed to load client")
        ).then(
            ()=> {
                if(!channel.localizations){
                    resolve(channel);
                    return (channel);
                }
                var params = {
                    part: [
                        "localizations"
                    ],
                    resource: {
                        "id": channel.id,
                        "localizations": channel.localizations
                    }
                }
                return window.gapi.client.youtube.channels.update(params).then(
                    (response) => resolve(response),
                    (error) => reject(error)
                );

            },
            (error) => reject(error)
        );


    }

    );
/*
    return load().then(
        () => {

            return window.gapi.client.youtube.channels.update(params);
        }
    );


    if (channel.localizations) {
        params.part[0] += ",localizations";
        params.resource.localizations = channel.localizations;
    }
*/
}

function saveVideoData(video) {
    var params = {
        part: [
            "snippet"
        ],
        resource: {
            "id": video.id,
            "snippet": video.snippet
        }
    }
    if (video.status) {
        params.part[0] = ",status";
        params.resource.status = video.status;
    }
    if (video.localizations) {
        params.part[0] += ",localizations";
        params.resource.localizations = video.localizations;
    }

    return load().then(
        () => {
            return window.gapi.client.youtube.videos.update(params);
        }
    );
}


function getVideoById(id) {
    var params = {
        part: VIDEO_PARTS,
        type: 'video',
        id: id
    }
    return load().then(
        () => {
            return window.gapi.client.youtube.videos.list(params);
        }
    );
}


function getChannelById(id) {
    var params = {
        part: CHANNEL_PARTS,
        type: 'channel',
        id: id
    }
    return load().then(
        () => {
            return window.gapi.client.youtube.channels.list(params);
        }
    );
}

function list(options) {
    var params = {
        part: CHANNEL_PARTS,
        type: 'channel',
        mine: true,
        maxResults: ((options.maxResults) ? options.maxResults : 10),
        pageToken: options.pageToken
    }
    return load().then(
        () => {
            //            return window.gapi.client.youtube.search.list(params);
            return window.gapi.client.youtube.channels.list(params);
        }
    );
}


function load() {
    var promise = Promise.resolve({});
    if (!window.gapi.client) {
        console.log('No google client loaded');
        return promise.then(() => {
            GoogleClient.init();
        }).then(() => window.gapi.client.load('youtube', 'v3'));
    } else if (!window.gapi.client.youtube) {
        console.log('Loading youtube client');
        return promise.then(() => window.gapi.client.load('youtube', 'v3'));
    }
    return promise;
}


export default {
    load: load,
    list: list,
    getChannelById: getChannelById,
    getVideosForChannel: getVideosForChannel,
    getMyVideos: getMyVideos,
    getLanguages: getLanguages,
    getVideoById: getVideoById,
    normalizeId: normalizeId,
    saveVideoData: saveVideoData,
    saveChannelData: saveChannelData
}