import React, { Component } from 'react';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import UserMenu from './UserMenu';
import InfoIcon from '@material-ui/icons/Info';
import AppMenuItem from './AppMenuItem';



export default class AppMenu extends Component {

    constructor(props) {
        super(props);
        this.hanldeItemClick = this.hanldeItemClick.bind(this);
    }

    hanldeItemClick() {
        if (this.props.handleAppDrawerClose) this.props.handleAppDrawerClose();
    }


    render() {
        return (
            <>
                <UserMenu handleAppDrawerClose={this.props.handleAppDrawerClose} />
                <Divider />
                <List>
                    <AppMenuItem path='/about' icon={InfoIcon} text='About' onClick={this.hanldeItemClick} />
                </List>
            </>
        )
    }
}
