import React, { Component } from 'react';
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import {
    getChannelById,
    updateChannel,
    resetChannel,
    saveChannel
} from '../actions'
import ChannelImage from './ChannelImage';
import PageTitle from '../../../PageTitle';
import LanguageSelect from '../../localizations/components/LanguageSelect';
import LocalizationsEditor from '../../localizations/components/LocalizationsEditor';
import KeywordsEditor from '../../../KeywordsEditor';
import styled from 'styled-components';
import Loadable from '../../../Loadable';
import DelayTextField from '../../../controls/DelayTextField';
import FormPostButtons from '../../../controls/FormPostButtons';


const StyledFormControl = styled(FormControl)`
& > * {
    margin: 10px !important;
};
`;

const StyledTextField = styled(DelayTextField)`
`;

const StyledLangugage = styled(LanguageSelect)`
`;

class ChannelForm extends Component {

    handleChannelChange(property, value) {
        if (this.props.updateChannel) this.props.updateChannel(property, value);
    }

    getEmptyChannel() {
        return {
            brandingSettings: {
                image: {},
                channel: {}
            },
            snippet: {
                thumbnails: {
                    default: {}
                }
            }
        }
    }

    render() {
        const channel = (this.props.channel) ? this.props.channel : this.getEmptyChannel();
        //if ((this.props.loading)||(!this.props.channel)) return <LinearProgress />;
        return (
            <>
                <PageTitle text={channel.brandingSettings.channel.title} />
                <Loadable
                    loading={this.props.loading}
                    component={ChannelImage}
                    mobileUrl={channel.brandingSettings.image.bannerMobileImageUrl}
                    tabletUrl={channel.brandingSettings.image.bannerTabletImageUrl}
                    avatarUrl={channel.snippet.thumbnails.default.url}
                />
                <Loadable
                    loading={this.props.loading}
                    component={StyledFormControl}
                    margin='dense'
                    fullWidth={true}
                    size='small'
                >
                    <StyledTextField
                        label="ID:"
                        value={channel.id}
                        variant="outlined"
                        helperText="Id of your channel. Couldn't be edited."
                        InputProps={{
                            readOnly: true,
                        }} />
                    <FormLabel>Default branding settings</FormLabel>
                    <StyledLangugage
                        label='Default Language'
                        value={channel.brandingSettings.channel.defaultLanguage}
                        variant="outlined"
                        helperText="Default channel language"
                        onSelect={(language, id) => this.handleChannelChange('brandingSettings.channel.defaultLanguage', id)}
                    />
                    <StyledTextField
                        label="Title:"
                        helperText="Default title can't be upadted. Use studio for that"
                        value={channel.brandingSettings.channel.title}
                        variant="outlined"
                        onChange={(event) => this.handleChannelChange('brandingSettings.channel.title', event.target.value)}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                    <StyledTextField
                        label="Description:"
                        multiline
                        helperText='Default description'
                        value={channel.brandingSettings.channel.description}
                        variant="outlined"
                        onChange={(event) => this.handleChannelChange('brandingSettings.channel.description', event.target.value)}
                    />
                    <FormLabel>Keywords</FormLabel>
                    <KeywordsEditor
                        label="Keywords"
                        keywords={channel.brandingSettings.channel.keywords}
                        onChange={(keywords) => this.handleChannelChange('brandingSettings.channel.keywords', keywords)}
                    />
                    <FormLabel>Localizations</FormLabel>
                    <LocalizationsEditor
                        localizations={channel.localizations}
                        variant="outlined"
                        onChange={(localizations) => this.handleChannelChange('localizations', localizations)}
                    />
                    <FormPostButtons
                        changed={this.props.changed}
                        onReset={this.props.resetChannel}
                        onSave={() => this.props.saveChannel(channel)}
                        inProgress={this.props.saving}
                    />
                </Loadable>
            </>
        )
    }
}

const youtubeChannelMap = (state) => {
    return {
        loading: state.youtube.channel.loading,
        changed: state.youtube.channel.changed,
        channel: state.youtube.channel.data,
        isSignedIn: state.google.user.isSignedIn,
        saving: state.youtube.channel.saving
    };
}


export default connect(
    youtubeChannelMap,
    {
        getChannelById,
        updateChannel,
        resetChannel,
        saveChannel
    }
)(ChannelForm)