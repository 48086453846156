import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextField from '@material-ui/core/TextField'

export default class DelayTextField extends Component {
    static propTypes = {
        onChange: PropTypes.func,
        timeout: PropTypes.number
    }

    static ID = 0;

    static defaultProps = {
        timeout: 2000
    }

    state = {
        id: 'delay' + (DelayTextField.ID++),
        value: this.props.value
    }

    timer = null

    disarmTimer() {
        if (this.timer){
            clearTimeout(this.timer);
            this.timer=null;
        }
    }

    componentDidUpdate() {
        if ((this.props.value !== this.state.value) && (!this.timer))
            this.setState({
                value: this.props.value
            })
    }

    armTimer(event) {
        event.preventDefault();
        event.stopPropagation();
        event.persist();
        this.timer = setTimeout(() => {
            this.propogateEvent(event);
            this.timer = null;
        }, this.props.timeout);
    }

    rearmTimer(event) {
        this.disarmTimer();
        this.armTimer(event);
    }


    propogateEvent(event) {
        if (this.props.onChange)
            this.props.onChange(event);
    }

    handleBlur(event) {
        if (this.timer) {
            this.disarmTimer();
            this.propogateEvent(event);
        }
    }

    handleChange(event) {
        this.setState({
            value: event.target.value
        })
        this.rearmTimer(event);
    }

    render() {
        var { timeout, onChange, ...props } = this.props;
        return (
            <TextField
                id={this.state.id}
                {...props}
                value={this.state.value}
                onChange={(event) => this.handleChange(event)}
                onBlur={(event) => this.handleBlur(event)}
            />
        )
    }
}
