import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import VKLogo from '@vkontakte/icons/dist/24/logo_vk';
import {
    vkGetProfile,
    vkDoLogout
} from '../actions'
import { Profile } from '../../../components/Profile';



export class VKProfile extends Component {
    static propTypes = {
        profile: PropTypes.object
    }

    updateProfile() {
        if ((this.props.isSignedIn) && (!this.props.profile) && (this.props.vkGetProfile)) {
            console.log("getProfile");
            this.props.vkGetProfile();
        }

    }

    componentDidMount() {
        console.log("did mount");
        this.updateProfile();
    }

    componentDidUpdate() {
        console.log("did update");
        this.updateProfile();
    }


    render() {
        return (
            <Profile 
                badge={VKLogo} 
                badgeColor='#4680C2' 
                name={this.props.name} 
                onSignOut={this.props.vkDoLogout}
                avatar={this.props.avatar}
                className={this.props.className}

            />
        )
    }
}

const mapStateToProps = (state) => ({
    profile: state.social.vkontakte.profile, 
    name: state.social.vkontakte.profile && state.social.vkontakte.profile.first_name+" "+state.social.vkontakte.profile.last_name,
    avatar: state.social.vkontakte.profile && state.social.vkontakte.profile.photo_50,
    isSignedIn: state.social.vkontakte.isSignedIn
})


const mapDispatchToProps = {
    vkGetProfile,
    vkDoLogout
}

export default connect(mapStateToProps, mapDispatchToProps)(VKProfile)
