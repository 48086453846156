import React, { Component } from 'react';
import './App.css';
import Toolbar from '@material-ui/core/Toolbar';
import AppNavBar from './components/AppNavBar/AppNavBar';
import {
  Switch,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';
import AppAlerts from './components/AppAlerts/AppAlerts';
import AppDrawer from './components/AppNavBar/AppDrawer';
import styled from 'styled-components';
import AppMenu from './components/AppNavBar/AppMenu';
import { connect } from 'react-redux';
import { simpleGoogleClientMap } from './components/google/utils';
import { googleClientLoadAndInit } from './components/google/client/actions';
import ChannelForm from './components/youtube/channel/components/ChannelForm';
import VideoList from './components/youtube/videos/components/VideoList'
import PageTitle from './components/PageTitle';
import AppRoute from './components/AppRoute';
import VideoForm from './components/youtube/video/components/VideoForm';
import AppError from './components/Errors/AppError';
import Sandbox from './components/Sandbox';
import ChannelsList from './components/youtube/channels/components/ChannelsList';

const StyledMain = styled.main`
flex-grow: 1;
padding: 0px;
`;


class App extends Component {

  state = {
    drawerOpen: false,
  };

  constructor(props) {
    super(props);
    this.handleMenuClick = this.handleMenuClick.bind(this);
  }

  static LoadRetry=10;

  loadClient() {
    if (
      (!this.props.ready)
      && (!this.props.error)
      && (this.props.googleClientLoadAndInit)
      && (!this.props.loading)
      && (App.LoadRetry>0)
    ) {
      this.props.googleClientLoadAndInit();
      App.LoadRetry--;
    }
  }

  componentDidMount() {
    this.loadClient();
  }


  componentDidUpdate() {
    this.loadClient();
  }

  handleMenuClick() {
    this.setState({
      drawerOpen: !this.state.drawerOpen
    });
  }

  render() {
    //    if (!this.props.ready) return <><AppError /><LoadingPage /></>;
    return (
      <div className="App">
        <Router>
          <AppAlerts>
            <AppError />
            <AppNavBar onMenuClick={this.handleMenuClick} />
            <AppDrawer open={this.state.drawerOpen} onDrawerClose={this.handleMenuClick}>
              <AppMenu />
            </AppDrawer>
            <StyledMain>
              <Toolbar />
              <Switch>
                <AppRoute path="/videos/:videoId" component={VideoForm} />
                <AppRoute path="/videos" component={VideoList} />
                <AppRoute path="/channels/:channelId" component={ChannelForm} />
                <AppRoute path="/channels" component={ChannelsList} />
                <Route path="/sandbox">
                  <Sandbox />
                </Route>
                <Route path="/">
                  <PageTitle />
                  Root Filler.
                  Here will be forms and lists to perform different updates for channel and videos.
                </Route>
              </Switch>
            </StyledMain>
          </AppAlerts>
        </Router>
      </div>
    );
  }
}

export default connect(
  simpleGoogleClientMap,
  { googleClientLoadAndInit }
)(App);
