import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import GoogleSignInButton from '../google/components/GoogleSignInButton';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';

const LeftAlignedCompoenents = styled.div`
margin-left: auto;
`;


const StyledAppBar = styled(AppBar)`
    z-index: var(-navbar-z-index) !important;
`;

export default class AppNavBar extends Component {
    static propTypes = {
        onMenuClick: PropTypes.func
    };
    static defaultProps = {
        onMenuClick: null
    };

    constructor(props) {
        super(props);
        this.handleMenuClick = this.handleMenuClick.bind(this);
    }


    handleMenuClick() {
        if (this.props.onMenuClick) this.props.onMenuClick();
    };

    render() {
        return (
            <StyledAppBar position="fixed">
                <Toolbar>
                    <Hidden mdUp implementation="css">
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={this.handleMenuClick}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>
                    <Typography component="h1" variant="h6" color="inherit" noWrap>Dream Tuber</Typography>
                    <LeftAlignedCompoenents>
                        <GoogleSignInButton theme='dark' />
                    </LeftAlignedCompoenents>
                </Toolbar>
            </StyledAppBar>
        )
    }
}


