import React, { Component } from 'react'
import List from '@material-ui/core/List';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SettingsIcon from '@material-ui/icons/Settings';
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay';
import { connect } from 'react-redux';
import AppMenuItem from './AppMenuItem';
import { withRouter } from 'react-router-dom'

class UserMenu extends Component {


    handleItemClick() {
        if (this.props.handleAppDrawerClose) this.props.handleAppDrawerClose();
    }

    channelSelect(channel) {
        if (channel) {
            this.props.history.push('/channel/' + channel.id + '/video');
        }
    }
    render() {
        if (!this.props.isSignedIn) return '';
        return (
            <>
                <List>
                    <AppMenuItem
                        path={'/channels'}
                        icon={YouTubeIcon}
                        text='My Channels'
                        onClick={() => this.handleItemClick()}
                    />
                    <AppMenuItem
                        path={'/videos'}
                        icon={VideoLibraryIcon}
                        text='My Videos'
                        onClick={() => this.handleItemClick()}
                    />
                    <AppMenuItem
                        path={'/playlists'}
                        icon={PlaylistPlayIcon}
                        text='My Playlists'
                        onClick={() => this.handleItemClick()}
                    />
                    <AppMenuItem path='/settings' icon={SettingsIcon} text='Settings' onClick={() => this.handleItemClick()} />
                    <AppMenuItem path='/sandbox' icon={SettingsIcon} text='Sandbox' onClick={() => this.handleItemClick()} />
                </List>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        isSignedIn: state.google.user.isSignedIn
    }
}


export default connect(
    mapStateToProps
)(withRouter(UserMenu))