import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChipInput from 'material-ui-chip-input'
import arrayRemove from 'lodash/pull'
import arrayUniq from 'lodash/uniq'
import styled from 'styled-components'
import Button from '@material-ui/core/Button'
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const FieldContainer = styled.div`
display: flex !important;
flex-direction: row;
`;

const StyledChipInput = styled(ChipInput)`
.max-width-no-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(var(--min-app-width) - 104px);
}
`;

export default class KeywordsEditor extends Component {
    static propTypes = {
        keywords: PropTypes.any,
        separator: PropTypes.string,
        label: PropTypes.string,
        variant: PropTypes.string,
        fullWidth: PropTypes.bool,
        onChange: PropTypes.func,
        keywordsRegexp: PropTypes.string
    }

    static defaultProps = {
        keywords: '',
        separator: ' ',
        keywordsRegexp: '\\s+',
        label: 'Keywords',
        variant: 'outlined',
        fullWidth: false
    }


    handleChange(values) {
        var valueString = this.getValueString(values);
        if (this.props.onChange) this.props.onChange(valueString, values);
    }

    handleAdd(chip) {
        if ((chip) && (chip.trim())) {
            var value = this.getValuesArray(this.props.keywords);
            var chipValue = this.getValuesArray(chip.trim());
            chipValue.map((item) => value.push(item));
            this.handleChange(arrayUniq(value));
        }
    }

    handleDelete(chip) {
        var value = this.getValuesArray(this.props.keywords);
        value = arrayRemove(value, chip);
        this.handleChange(value);
    }

    getValuesArray(keywords) {
        if (Array.isArray(keywords)) return arrayUniq(keywords);
        return (keywords) ? arrayUniq(keywords.split(new RegExp(this.props.keywordsRegexp))) : [];
    }

    getValueString(keywords) {
        if (Array.isArray(keywords)) return keywords.join(this.props.separator);
        return keywords;
    }

    render() {
        return (
            <FieldContainer className={this.props.className}>
                <StyledChipInput
                    label={this.props.label}
                    variant={this.props.variant}
                    fullWidth
                    newChipKeys={[this.props.separator]}
                    value={this.getValuesArray(this.props.keywords)}
                    onAdd={(chip) => this.handleAdd(chip)}
                    onDelete={(chip) => this.handleDelete(chip)}
                    classes={{
                        chip: "max-width-no-overflow"
                    }}
                />
                <CopyToClipboard text={this.getValueString(this.props.keywords)}>
                    <Button><FileCopyIcon /></Button>
                </CopyToClipboard>
            </FieldContainer>
        )
    }
}


