import React, { Component } from 'react'
import { connect } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { appDismissError } from '../../actions'


export class AppError extends Component {

    stripTags(html) {
        if (!html) return html;
        return html.replace(/<[^>]+>/g, '')
    }

    render() {
        return (
            <Snackbar open={this.props.open}>
                <div>
                    {this.props.errors && this.props.errors.map((error, index) => (
                        <Alert key={index} severity="error" onClose={() => this.props.appDismissError(error)}>
                            <AlertTitle>{this.stripTags(error.message)}</AlertTitle>
                            {this.stripTags(error.causeMessage)}
                        </Alert>
                    ))}
                </div>
            </Snackbar>
        )
    }
}

const mapStateToProps = (state) => {

    var errors = [
        state.google.client.error,
        state.google.user.error,
        state.youtube.channel.error,
        state.youtube.channels.error,
        state.youtube.client.error,
        state.youtube.video.error,
        state.youtube.videos.error,
        state.youtube.languages.error
    ];

    errors = errors.filter((error) => {
        return (error);
    });

    return {
        open: (errors.length > 0),
        errors
    }
};

export default connect(
    mapStateToProps,
    { appDismissError }
)(AppError)
