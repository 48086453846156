import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components';

import { getUserChannels } from '../actions'

import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableFooter from '@material-ui/core/TableFooter';
import PageTitle from '../../../PageTitle';
import ChannelListItem from './ChannelListItem';

const StyledTableContainer = styled(TableContainer)`
padding: 15px;
text-align: left;
`;

const StyledFooter = styled(TableFooter)`
overflow: hidden;
padding:0;
`;

const StyledFooterCell = styled(TableCell)`
overflow: hidden;
padding:0 !important;
`;

const StyledPagination = styled(TablePagination)`
overflow: hidden;
padding:0 !important;
.pagination-toolbar{
    overflow: hidden;
    padding:0 !important;
};
.margin-right-reduce {
    margin-right: 8px;
};
.margin-left-reduce {
    margin-left: 8px;
};
`;


export class ChannelsList extends Component {


    updateChannel(term, pageToken, page = 0, maxResults = 10) {
        if ((!this.props.error) && (!this.props.loading)) {
            this.props.getUserChannels(maxResults, pageToken, page);
        }
    }

    getChannels() {
        if (!this.props.list.items)
            this.updateChannel(this.props.list.term, this.props.list.pageToken, this.props.list.page, this.props.list.resultsPerPage);
    }

    componentDidMount() {
        this.getChannels();
    }

    componentDidUpdate() {
        this.getChannels();
    }

    onChannelClick(channel){
        this.props.history.push(this.props.location.pathname + "/" + channel.id);
    }

    handlePageChange(event, page) {
        var token = (page > this.props.list.page) ? this.props.list.nextPageToken : this.props.list.prevPageToken;
        this.updateChannel(this.props.list.term, token, page, this.props.list.resultsPerPage);
    }

    handleMaxResultsChange(event) {
        this.updateChannel(this.props.list.term, null, null, event.target.value);
    }



    render() {
        const maxResults = ((this.props.list.resultsPerPage)&&(this.props.list.resultsPerPage>=10)) ? this.props.list.resultsPerPage : 10;
        const results = (this.props.list.totalResults) ? this.props.list.totalResults : 0;
        const page = (this.props.list.page) ? this.props.list.page : 0;
        return (
            <StyledTableContainer>
                <PageTitle text='My Channels' />
                <Table size='small' style={{ tableLayout: 'fixed', width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Channels</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.props.list.items && this.props.list.items.map((channel) => (
                            <TableRow key={channel.id}><TableCell>
                                <ChannelListItem 
                                channel={channel}
                                href={this.props.location.pathname + "/" + channel.id}
                                onClick={()=>this.onChannelClick(channel)}
                                />
                            </TableCell></TableRow>
                        ))}
                    </TableBody>
                    <StyledFooter>
                        <TableRow>
                            <StyledFooterCell>
                                <StyledPagination
                                    component='div'
                                    rowsPerPageOptions={[10, 25, 50]}
                                    count={results}
                                    labelRowsPerPage=''
                                    rowsPerPage={maxResults}
                                    labelDisplayedRows={({ from, to, count }) => (count < 100000) ? (from + '-' + to + ' of ' + count) : (from + '-' + to)}
                                    page={page}
                                    onChangePage={this.handlePageChange}
                                    onChangeRowsPerPage={this.handleMaxResultsChange}
                                    classes={{
                                        toolbar: 'pagination-toolbar',
                                        selectRoot: 'margin-right-reduce',
                                        actions: 'margin-left-reduce'
                                    }}
                                />
                            </StyledFooterCell>
                        </TableRow>
                    </StyledFooter>
                </Table>
            </StyledTableContainer>
        )
    }
}

const youtubeChannelsMap = (state) => {
    return { 
        isSignedIn: state.google.user.isSignedIn,
        loading: state.youtube.channels.loading,
        channel: state.youtube.channel.data,
        list: state.youtube.channels.list,
        error: state.youtube.channels.error,
        channelLoading: state.youtube.channel.loading
    };
}



export default connect(
    youtubeChannelsMap,
    { getUserChannels }
)(ChannelsList)
