import React, { Component } from 'react'
import PropTypes from 'prop-types'

export default class SocialWidget extends Component {
    static propTypes = {
        isSignedIn: PropTypes.bool,
        profileBadge: PropTypes.any.isRequired,
        loginButton: PropTypes.any.isRequired,
    }


    render() {
        const Profile=this.props.profileBadge;
        const Button=this.props.loginButton;
        return (
            (this.props.isSignedIn)?<Profile className={this.props.className}/>:<Button className={this.props.className}/>
        )
    }
}
