

class Social {
    //static _loadables = require.context('./modules/', true, /socialModule\.js$/)

    static _loadables = [
        'facebook',
        'vkontakte'
    ]
    static _modules = {};
    
    static _cacheModule(key, resource) {
        //console.log('resource',resource);
        var moduleName = Object.keys(resource)[0];
        if (moduleName) {
            Social._modules[moduleName] = {
                ...resource[moduleName],
                id: moduleName
            };
        }
    }

    static _importAll(resources) {
        if(resources)
            resources.forEach(key => Social._cacheModule(key,require('./modules/'+key+'/socialModule.js')));
    }

    static import(){
        Social._importAll(Social._loadables);
    }

    constructor(){
        //console.log('Construction');
        Social.import();
    }
        
    getModules(){
        return Social._modules;
    }

    getModulesArray() {
        return Object.keys(Social._modules).map(key =>Social._modules[key]);
    }

    getReducers() {
        var reducers={}
        Object.keys(Social._modules).forEach(key => {if(Social._modules[key].reducer)reducers[key]=Social._modules[key].reducer});
        return reducers;
    }
}

const SocialInstance=new Social();

export default SocialInstance;