import React, { Component } from 'react'

export default class Unauthorized extends Component {
    render() {
        return (
            <div>This page require you to authorize through your YouTube Account. Please, use SignIn button in right upper conner.
            </div>
        )
    }
}
