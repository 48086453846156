import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import CircularProgress from '@material-ui/core/CircularProgress';
import Search from '@material-ui/icons/Search';


const StyledGroup = styled.div`
display: flex;
flex-direction: row;
width: 100%;
`;

const SearchField = styled(TextField)`
width: 100%;
padding-right: 5px !important;
`;
export default class SearchContainer extends Component {
    static propTypes = {
        onSearch: PropTypes.func.isRequired,
        loading: PropTypes.bool.isRequired,
        term: PropTypes.string
    }

    static defaultProps = {
        onSearch: null,
        loading: false,
        term: ''
    }

    state = {
        term: ''
    }

    constructor(props) {
        super(props);
        this.handleSearchChange = this.handleSearchChange.bind(this);
        this.hanldeKeyPress=this.hanldeKeyPress.bind(this);
        this.runSearch=this.runSearch.bind(this);
    }

    componentDidMount() {
        this.setState({
            term: this.props.term
        });
    }

    handleSearchChange(event) {
        var runSearchNeeded=((this.state.term!=='')&&(event.target.value===''));
        this.setState({
            term: event.target.value
        });
        if(runSearchNeeded)this.runSearch();
    }

    runSearch() {
        if (this.props.onSearch) 
            this.props.onSearch(this.state.term);

    }

    hanldeKeyPress(event) {
        if (event.key === 'Enter') {
            this.runSearch()
        }
    }

    render() {
        return (
            <StyledGroup>
                <SearchField
                    type="search"
                    defaultValue={this.props.term}
                    disabled={this.props.loading}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                {!this.props.loading && <Search />}
                                {this.props.loading && <CircularProgress size='1rem' />}
                            </InputAdornment>
                        )
                    }}
                    onChange={this.handleSearchChange}
                    onKeyPress={this.hanldeKeyPress}
                />

                <Button
                    disabled={this.props.loading}
                    color='primary'
                    onClick={this.runSearch}
                >
                    Search
                    </Button>
            </StyledGroup>
        )
    }
}
