import {
    YOUTUBE_CLIENT_LOAD,
    YOUTUBE_CLIENT_LOAD_SUCCESS,
    YOUTUBE_CLIENT_LOAD_FAILED,
} from '../actions/actionTypes'
import { reduceError } from '../../../../utils'



const initialState = {
    loading: false,
    loaded: false,
    ready: false,
    error: null
};


export default function defaultReducer(state = initialState, action) {
    state = reduceError(state, action);
    switch (action.type) {
        case YOUTUBE_CLIENT_LOAD:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: null
            }
        case YOUTUBE_CLIENT_LOAD_SUCCESS:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: null
            }
        case YOUTUBE_CLIENT_LOAD_FAILED:
            return {
                ...state,
                loading: false,
                loaded: false,
                error: {
                    message: 'Failed to load client',
                    details: action.details,
                    causeMessage: action.causeMessage,
                    causeCode: action.causeCode
                }
            }
        default:
            return state;
    }
}

