import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { getChannelById,selectChannel } from './youtube/channel/actions'
import { Redirect } from 'react-router-dom';
import { getVideo } from './youtube/video/actions'
import Unauthorized from './Unauthorized';

const mapStateToProps = (state) => {
    return {
        channelId: (state.youtube.channel.data) ? state.youtube.channel.data.id : null,
        videoId: (state.youtube.video.data) ? state.youtube.video.data.id : null,
        loading: (state.youtube.channel.loading && state.youtube.video.loading),
        signedIn: state.google.user.isSignedIn,
        channelError: state.youtube.channel.error,
        videoError: state.youtube.video.error
    }
};


class AppUrlAttendy extends Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        component: PropTypes.object.isRequired,
        checkSignedIn: PropTypes.bool.isRequired
    };

    
    static defaultProps = {
        checkSignedIn: true
    }

    componentDidMount() {
        this.reselectItems();
    }
    componentDidUpdate() {
        this.reselectItems();
    }

    reselectItems(){
        if(!this.props.signedIn)return;
        var channelId = this.props.match.params.channelId;
        var videoId = this.props.match.params.videoId;
        if (this.props.channelId !== channelId) {
            if((channelId)&&(!this.props.channelError)&&(!this.props.loading))
                this.props.getChannelById(channelId);
        }
        if (this.props.videoId !== videoId) {
            console.log('Video needs to be reselected');
            if((!this.props.videoError)&&(videoId)&&(!this.props.loading))
                this.props.getVideo(videoId);
        }
    }


    getStateUrl() {
        var returnUrl='/';
        if(!this.props.channelId) return returnUrl;
        return this.props.match.path.replace('/:channelId', '/' + this.props.channelId).replace('/:videoId', '/' + this.props.videoId);
    }




    getRedirectIfRequired() {
       // if(this.props.)
        if(this.props.loading) return '';
        if (this.getStateUrl(this.props.match.path) !== this.props.match.url) {
            return <Redirect to={this.getStateUrl()} push />
        }
        const ChildComponent=this.props.component;
        return <ChildComponent {...this.props} attendyProps/>;
    }

    render() {
        if((this.props.checkSignedIn)&&(!this.props.signedIn)) return <Unauthorized/>;
        const ChildComponent=this.props.component;
//        if (this.state.redirectRequired) return  this.getRedirectIfRequired();
        return <ChildComponent {...this.props} attendyProps/>;
    }
}


export default connect(
    mapStateToProps,
    { getChannelById, selectChannel, getVideo }
)(AppUrlAttendy)