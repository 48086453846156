import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import Avatar from '@material-ui/core/Avatar'
import { simpleGoogleUserMap } from '../utils';
import { connect } from 'react-redux';

const ProfileLabelWrapper = styled.div`
white-space: nowrap;
flex-wrap: nowrap;
vertical-align: middle;
margin: 0px;
margin-left:0px;
margin-right:0px;
display:flex;
justify-content: center;
align-items: center;
`;

const ProfileImage = styled(Avatar)`
vertical-align: middle;
justify-content: center;
`;

const ProfileName = styled.div`
color: ${props => (props.theme === 'dark') ? '#FFFFFF' : '#000000'};
vertical-align: middle;
height: 100%;
padding-left: 15px;
padding-right: 15px;
font-size: 16px;
font-weight: normal;
font-family: 'Roboto', sans-serif;
white-space: nowrap;
flex-wrap: nowrap;
`;


class GoogleProfileLabel extends Component {
    static propTypes = {
        theme: PropTypes.oneOf(['light', 'dark'])
    }

    static defaultProps = {
        theme: 'dark'
    }

    render() {
        if (!this.props.profile) return '';
        return (
            <ProfileLabelWrapper>
                <ProfileImage theme={this.props.theme} src={this.props.profile.getImageUrl()} />
                <ProfileName theme={this.props.theme}>{this.props.profile.getName()} </ProfileName>
            </ProfileLabelWrapper>
        )
    }
}



export default connect(
    simpleGoogleUserMap
)(GoogleProfileLabel)

/*


*/