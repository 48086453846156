import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import {
    updateVideo,
    resetVideo,
    saveVideo
} from '../actions'
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Loadable from '../../../Loadable';
import styled from 'styled-components';
import KeywordsEditor from '../../../KeywordsEditor';
import LocalizationsEditor from '../../localizations/components/LocalizationsEditor';
import LanguageSelect from '../../localizations/components/LanguageSelect';
import PageTitle from '../../../PageTitle';
import DelayTextField from '../../../controls/DelayTextField';
import FormPostButtons from '../../../controls/FormPostButtons';


const StyledFormControl = styled(FormControl)`
& > * {
    margin: 10px !important;
};
`;

const StyledVideoHead = styled.div`
display: flex;
flex-grow: row;
& > * {
    padding: 5px !important;
};
`;

const VideoImg = styled.img`
`;



export class VideoForm extends Component {
    static propTypes = {
        local: PropTypes.string
    }

    getEmptyVideo() {
        return {
            snippet: {
                thumbnails: {
                    default: {}
                }
            }
        }
    }

    handleVideoChange(property, value) {
        if (this.props.updateVideo) this.props.updateVideo(property, value);
    }


    render() {
        const video = (this.props.video) ? this.props.video : this.getEmptyVideo();
        return (
            <Loadable
                loading={this.props.loading}
                component={StyledFormControl}
                fullWidth
                size='small'>
                <PageTitle text={video.snippet.title} />
                <StyledVideoHead>
                    <VideoImg
                        src={video.snippet.thumbnails.default.url}
                        alt={video.snippet.title}
                    />
                    <DelayTextField
                        label="ID:"
                        defaultValue={video.id}
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                </StyledVideoHead>
                <FormLabel>Default Tittle and description</FormLabel>
                <LanguageSelect
                    label='Default Language'
                    value={video.snippet.defaultLanguage}
                    variant="outlined"
                    helperText="Default channel language"
                    onSelect={(language, id) => this.handleVideoChange('snippet.defaultLanguage', id)}
                />
                <DelayTextField
                    label="Title:"
                    helperText='Default title'
                    value={video.snippet.title}
                    variant="outlined"
                    onChange={(event) => this.handleVideoChange('snippet.title', event.target.value)}
                    inputProps={{ maxLength: 100 }}
                />
                <DelayTextField
                    label="Description:"
                    multiline
                    helperText='Default description'
                    value={video.snippet.description}
                    variant="outlined"
                    onChange={(event) => this.handleVideoChange('snippet.description', event.target.value)}
                    inputProps={{ maxLength: 5000 }}
                />
                <FormLabel>Keywords</FormLabel>
                <KeywordsEditor
                    label="Keywords"
                    keywords={video.snippet.tags}
                    separator=','
                    keywordsRegexp='[\s+]?,[\s+]?'
                    onChange={(str, tags) => this.handleVideoChange('snippet.tags', tags)}
                />
                <FormLabel>Localizations</FormLabel>
                <LocalizationsEditor
                    localizations={video.localizations}
                    variant="outlined"
                    onChange={(localizations) => this.handleVideoChange('localizations', localizations)}
                />
                <FormPostButtons
                    changed={this.props.changed}
                    onReset={this.props.resetVideo}
                    onSave={() => this.props.saveVideo(video)}
                    inProgress={this.props.saving}
                />
            </Loadable>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.youtube.video.loading,
    video: state.youtube.video.data,
    changed: state.youtube.video.changed,
    saving: state.youtube.video.saving
})

export default connect(
    mapStateToProps,
    {
        updateVideo,
        resetVideo,
        saveVideo
    }
)(VideoForm)
