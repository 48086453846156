
import {
    FACEBOOK_LOGIN,
    FACEBOOK_LOGOUT,
    FACEBOOK_PROFILE_LOAD_SUCCESS,
    FACEBOOK_PROFILE_IMAGE_LOAD_SUCCESS,

} from '../actions/actionTypes';

const initialState = {
    error: null,
    profile: null,
    isSignedIn: false
};


export default function defaultReducer(state = initialState, action) {
    switch (action.type) {
        case FACEBOOK_LOGIN:
            return {
                ...state,
                isSignedIn: true
            }
        case FACEBOOK_LOGOUT:
            return initialState;
        case FACEBOOK_PROFILE_LOAD_SUCCESS:
            return {
                ...state,
                profile: action.profile
            }
            case FACEBOOK_PROFILE_IMAGE_LOAD_SUCCESS:
                return {
                    ...state,
                    profile: {
                        ...state.profile,
                        image: action.image
                    }
                }
        default:
            return state;
    }
}