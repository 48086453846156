
import {
    FACEBOOK_CLIENT_LOAD,
    FACEBOOK_CLIENT_LOAD_FAILURE,
    FACEBOOK_CLIENT_LOAD_SUCCESS,
    FACEBOOK_LOGIN,
    FACEBOOK_LOGOUT,
    FACEBOOK_PROFILE_LOAD,
    FACEBOOK_PROFILE_LOAD_SUCCESS,
    FACEBOOK_PROFILE_LOAD_FAILURE,
    FACEBOOK_PROFILE_IMAGE_LOAD,
    FACEBOOK_PROFILE_IMAGE_LOAD_SUCCESS,
    FACEBOOK_PROFILE_IMAGE_LOAD_FAILURE
} from './actionTypes';

import FBClient from '../api'

export function fbLogin() {
    return {
        type: FACEBOOK_LOGIN,
    };
}

export function fbLogout() {
    return {
        type: FACEBOOK_LOGOUT,
    };
}


export function fbClientLoadCall() {
    return {
        type: FACEBOOK_CLIENT_LOAD,
    };
}

export function fbClientLoadSuccess() {
    return {
        type: FACEBOOK_CLIENT_LOAD_SUCCESS,
    };
}

export function fbClientLoadFailure() {
    return {
        type: FACEBOOK_CLIENT_LOAD_FAILURE,
    };
}

function authHandler(response, dispatch) {
    if (response.status === 'connected') {
        dispatch(fbLogin());
    } else {
        dispatch(fbLogout());
    }
}

export function fbDoLogin() {
    return function (dispatch) {
        FBClient.login().then(
            () => dispatch(fbLogin()),
            () => dispatch(fbLogout())
        );
    }
}



export function fbDoLogout() {
    return function (dispatch) {
        FBClient.logout().then(
            () => dispatch(fbLogout()),
            () => dispatch(fbLogout())
        );
    }
}



function loadClient(dispatch = () => { }) {
    return new Promise((resolve, reject) => {
        if (FBClient.isApiLoaded()) {
            resolve();
            return;
        }
        dispatch(fbClientLoadCall());
        FBClient.load().then(
            (alreadyLoaded) => {
                if (!alreadyLoaded) {
                    FBClient.getLoginStatus((response) => authHandler(response, dispatch));
                    FBClient.subscribeAuthStatusChange((response) => authHandler(response, dispatch));
                }
                dispatch(fbClientLoadSuccess());
                resolve();
            }
            ,
            () => {
                dispatch(fbClientLoadFailure());
                reject();
            }
        )

    }
    );
}


export function fbClientLoad() {
    return function (dispatch) {
        loadClient(dispatch).then();
    }
}


export function fbGetProfileCall() {
    return {
        type: FACEBOOK_PROFILE_LOAD,
    };
}

export function fbGetProfileFailure(error) {
    return {
        type: FACEBOOK_PROFILE_LOAD_FAILURE,
        error
    };
}

export function fbGetProfileSuccess(profile) {
    return {
        type: FACEBOOK_PROFILE_LOAD_SUCCESS,
        profile
    };
}

export function fbGetProfile() {
    return function (dispatch) {
        loadClient(dispatch).then(() => {
            dispatch(fbGetProfileCall());
            FBClient.getProfile().then(
                (response) => {
                    dispatch(fbGetProfileSuccess(response));
                    dispatch(fbGetProfileImage());
                }
                ,
                (error) => dispatch(fbGetProfileFailure(error))
            );
        }
        );
    }
}


export function fbGetProfileImageCall() {
    return {
        type: FACEBOOK_PROFILE_IMAGE_LOAD,
    };
}

export function fbGetProfileImageFailure(error) {
    return {
        type: FACEBOOK_PROFILE_IMAGE_LOAD_FAILURE,
        error
    };
}

export function fbGetProfileImageSuccess(image) {
    return {
        type: FACEBOOK_PROFILE_IMAGE_LOAD_SUCCESS,
        image: image.data
    };
}

export function fbGetProfileImage() {
    return function (dispatch) {
        loadClient(dispatch).then(() => {
            dispatch(fbGetProfileImageCall());
            FBClient.getProfileImage().then(
                (response) => dispatch(fbGetProfileImageSuccess(response)),
                (error) => dispatch(fbGetProfileImageFailure(error))
            );
        }
        );
    }
}