import {
    VK_LOGIN,
    VK_LOGOUT,
    VK_CLIENT_LOAD,
    VK_CLIENT_LOAD_SUCCESS,
    VK_CLIENT_LOAD_FAILURE,
    VK_PROFILE_LOAD,
    VK_PROFILE_LOAD_SUCCESS,
    VK_PROFILE_LOAD_FAILURE
} from '../actions/actionTypes'
import VKClient from '../api'

export function vkLogin(source) {
    return {
        type: VK_LOGIN,
        source
    };
}

export function vkLogout(source) {
    return {
        type: VK_LOGOUT,
        source
    };
}


export function vkClientLoadCall() {
    return {
        type: VK_CLIENT_LOAD,
    };
}

export function vkClientLoadSuccess() {
    return {
        type: VK_CLIENT_LOAD_SUCCESS,
    };
}

export function vkClientLoadFailure() {
    return {
        type: VK_CLIENT_LOAD_FAILURE,
    };
}

function authHandler(response, dispatch, number = 0) {
    if (response.status === 'connected') {
        dispatch(vkLogin("handler"));
    } else {
        dispatch(vkLogout("handler"));
    }
}

export function vkDoLogin() {
    return function (dispatch) {
        loadClient(dispatch).then(
            () => VKClient.login().then()
        );
    }
}



export function vkDoLogout() {
    return function (dispatch) {
        loadClient(dispatch).then(
            () => VKClient.logout().then()
        );
    }
}

function loadClient(dispatch = () => { }) {
    return new Promise((resolve, reject) => {
        if (VKClient.isApiLoaded()) {
            resolve();
            return;
        }
        dispatch(vkClientLoadCall());
        VKClient.load().then(
            (alreadyLoaded) => {
                if (!alreadyLoaded) {
                    VKClient.subscribeAuthStatusChange((response) => authHandler(response, dispatch));
                    VKClient.getLoginStatus((response) => authHandler(response, dispatch));
                }
                dispatch(vkClientLoadSuccess());
                resolve();
            },
            () => {
                dispatch(vkClientLoadFailure());
                reject();
            }
        );
    });
}



export function vkClientLoad() {
    return function (dispatch) {
        loadClient(dispatch).then();
    }
}

export function vkProfileLoadCall() {
    return {
        type: VK_PROFILE_LOAD,
    };
}

export function vkProfileLoadSuccess(profile) {
    return {
        type: VK_PROFILE_LOAD_SUCCESS,
        profile
    };
}

export function vkProfileLoadFailure(error) {
    return {
        type: VK_PROFILE_LOAD_FAILURE,
        error
    };
}




export function vkGetProfile() {
    return function (dispatch) {
        console.log("vkGetProfile");
        loadClient(dispatch).then(
            () => {
                dispatch(vkProfileLoadCall());
                VKClient.getCurrentProfile().then(
                    (data) => {
                        dispatch(vkProfileLoadSuccess(data));
                    },
                    (error) => {
                        dispatch(vkProfileLoadFailure(error));
                    }
                );
            },
            () => {
                dispatch(vkProfileLoadFailure());
            }
        );
    }

}
