import { combineReducers } from "redux";
import client from '../client/reducer';
import channels from '../channels/reducer';
import channel from '../channel/reducer';
import videos from '../videos/reducer';
import video from '../video/reducer';
import languages from '../localizations/reducer'

export default combineReducers({
    client,
    channel,
    channels,
    videos,
    video,
    languages
});