import React, { Component } from 'react'
import styled from 'styled-components'



const StyledButtonGroup=styled.div`
& > * {
    margin: 10px !important;
};
`;

export default class ButtonGroup extends Component {
    render() {
        return (
            <StyledButtonGroup className={this.props.className}>
                {this.props.children}
            </StyledButtonGroup>
        )
    }
}
