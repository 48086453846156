import {
    YOUTUBE_GET_CHANNEL_BY_ID,
    YOUTUBE_GET_CHANNEL_BY_ID_SUCCESS,
    YOUTUBE_GET_CHANNEL_BY_ID_FAILURE,
    YOUTUBE_UPDATE_CHANNEL,
    YOUTUBE_SELECT_CHANNEL,
    YOUTUBE_RESET_CHANNEL,
    YOUTUBE_SAVE_CHANNEL,
    YOUTUBE_SAVE_CHANNEL_SUCCESS,
    YOUTUBE_SAVE_CHANNEL_FAILURE
} from './actionTypes';


import YouTubeClient from '../../../../apis/YouTube/YouTubeClient'

export function selectChannel(channel) {
    return {
        type: YOUTUBE_SELECT_CHANNEL,
        data: channel
    };
}



export function updateChannel(property, value) {
    return {
        type: YOUTUBE_UPDATE_CHANNEL,
        property: property,
        value: value
    };
}

export function getChannelByIdCall() {
    return {
        type: YOUTUBE_GET_CHANNEL_BY_ID
    };
}


export function getChannelByIdSuccess(response) {
    return {
        type: YOUTUBE_GET_CHANNEL_BY_ID_SUCCESS,
        successResponse: response
    };
}

export function getChannelByIdFailure(errorResponse) {
    return {
        type: YOUTUBE_GET_CHANNEL_BY_ID_FAILURE,
        errorResponse: errorResponse
    };
}


export function getChannelById(id) {
    return function (dispatch) {
        dispatch(getChannelByIdCall());
        YouTubeClient.getChannelById(id)
            .then(
                (youtubeResponse) => {
                    dispatch(getChannelByIdSuccess(youtubeResponse))
                    return youtubeResponse;
                },
                (errorResponse) => dispatch(getChannelByIdFailure(errorResponse))
            ).then(
                (response) => {
                    if ((response.result) && (response.result.items) && (response.result.items.length > 0)) {
                        dispatch(selectChannel(response.result.items[0]));
                    }
                }
            )
            .catch(
                (errorResponse) => dispatch(getChannelByIdFailure(errorResponse))
            );
    }
}


export function resetChannel(){
    return {
        type: YOUTUBE_RESET_CHANNEL,
    }
    
}

export function saveChannelCall() {
    return {
        type: YOUTUBE_SAVE_CHANNEL
    };
}


export function saveChannelSuccess(response) {
    return {
        type: YOUTUBE_SAVE_CHANNEL_SUCCESS,
        successResponse: response
    };
}

export function saveChannelFailure(errorResponse) {
    return {
        type: YOUTUBE_SAVE_CHANNEL_FAILURE,
        errorResponse: errorResponse
    };
}



export function saveChannel(data) {
    return function (dispatch) {
        dispatch(saveChannelCall());
        YouTubeClient.saveChannelData(data)
            .then(
                (youtubeResponse) => {
                    dispatch(saveChannelSuccess(youtubeResponse))
                    return youtubeResponse;
                },
                (errorResponse) => dispatch(saveChannelFailure(errorResponse))
            )
            .catch(
                (errorResponse) => dispatch(saveChannelFailure(errorResponse))
            );
    }
}